<template>
	<div class="p-4">
		<p class="text-2xl">Productos</p>
		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex flex-row space-x-4 items-end w-full">
				<div class="w-full">
					<p class="pb-2 text-sm">Estatus</p>
					<select
						v-model="activo"
						class="select select-sm select-bordered w-full"
					>
						<option :value="true">Activo</option>
						<option :value="false">Inactivo</option>
					</select>
				</div>

				<div class="w-full">
					<p class="pb-2 text-sm">Tipo</p>
					<select
						v-model="tipo"
						class="select select-sm select-bordered w-full"
					>
						<option value="">Todo</option>
						<option value="vino">Vino</option>
						<option value="kit">Kit</option>
						<option value="empaque">Empaque</option>
						<option value="club">Club</option>
						<option value="accesorio">Accesorio</option>
					</select>
				</div>

				<div class="w-full">
					<button
						@click="generateCSV()"
						class="btn btn-primary btn-sm no-animation w-full"
					>
						CSV
					</button>
				</div>
			</div>

			<div class="overflow-x-auto pt-4">
				<table class="table">
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Data</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="producto in filteredProductos"
							v-bind:key="producto.id"
							class="cursor-pointer hover"
							@click="goToProducto(producto.id)"
						>
							<td>
								<div class="flex items-center gap-3">
									<div class="avatar">
										<div
											v-if="producto.photo"
											class="rounded-lg h-12 w-12"
										>
											<img
												:src="producto.photo"
												loading="lazy"
												alt="Avatar Tailwind CSS Component"
											/>
										</div>

										<div
											v-else
											class="w-12 bg-gray-400 rounded-lg"
										></div>
									</div>
									<div>
										<div class="font-bold">{{ producto.nombre }}</div>
										<div class="text-sm opacity-50">
											{{ producto.tipo }}
											{{
												producto.meta && producto.meta.tipo ? producto.meta.tipo.toLowerCase() : ''
											}}
										</div>
									</div>
								</div>
							</td>
							<td>
								<span
									v-if="producto.meta.situacion"
									class="badge badge-ghost badge-sm"
									>{{ producto.meta.situacion ? 'Situaciones' : '' }}</span
								>
								<span
									v-if="producto.meta.perfiles"
									class="badge badge-ghost badge-sm"
									>{{ producto.meta.perfiles ? 'Perfiles' : '' }}
								</span>
								<span
									v-if="producto.meta.personalidades"
									class="badge badge-ghost badge-sm"
									>{{ producto.meta.personalidades ? 'Personalidades' : '' }}</span
								>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
	name: 'AdminProductos',
	setup() {
		const productos = ref([])
		const router = useRouter()

		const activo = ref(true)
		const tipo = ref('')

		const filteredProductos = computed(() => {
			return productos.value.filter((p) => {
				return p.activo === activo.value && (!tipo.value || p.tipo === tipo.value)
			})
		})

		async function getProductos() {
			const { data } = await supabase
				.from('productos')
				.select(
					'created_at, id, nombre, proveedor (nombre), shopify_id, shopify_handle, precio, costo, activo, tipo, meta, photo'
				)
				.order('nombre', { ascending: true })

			if (data) {
				return data.sort((a, b) => {
					const nombreA = a.nombre || ''
					const nombreN = b.nombre || ''
					return nombreA.localeCompare(nombreN)
				})
			} else {
				alert('No data returned')
			}
		}

		function goToProducto(productoId) {
			if (event.getModifierState('Meta')) {
				window.open(`/producto/${productoId}`, '_blank').focus()
			} else {
				router.push(`/producto/${productoId}`)
			}
		}

		onMounted(async () => {
			productos.value = await getProductos()
		})

		function generateCSV() {
			let csv = ''

			productos.value.forEach((p) => {
				let a = [
					p.proveedor ? `\n${p.proveedor.nombre.replaceAll(',', '')}` : '\n',
					`${p.nombre.replaceAll(',', '')}`,
					p.shopify_handle,
					p.shopify_id,
					p.costo,
					p.precio,
					p.created_at
				]
				csv += a
			})

			const anchor = document.createElement('a')
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
			anchor.target = '_blank'
			anchor.download = 'inventario.csv'
			anchor.click()
		}

		return {
			activo,
			tipo,
			productos,
			filteredProductos,
			generateCSV,
			goToProducto
		}
	}
}
</script>
