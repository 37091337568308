<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Blast</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="w-full space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Nombre</p>
					<input
						class="input input-bordered input-sm w-full"
						placeholder="Nombre"
						type="search"
						v-model="broadcast"
					/>
				</div>
			</div>

			<div class="w-full">
				<p class="text-sm pb-2">Template</p>
				<select
					v-model="template"
					class="select select-sm select-bordered w-full"
				>
					<option
						selected
						disabled
						:value="null"
					>
						Escoger un template
					</option>
					<option
						v-for="template in templates"
						v-bind:key="template.elementName"
						:value="template.elementName"
					>
						{{ template.elementName }}
					</option>
				</select>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<p class="text-xl">Clientes ({{ clientes.length }})</p>

			<div
				v-if="clientes.length > 0"
				class="flex w-full mt-0"
			>
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/3">Nombre</th>
							<th class="w-1/3">Telefono</th>
							<th class="w-1/3">Correo</th>

							<th class="min-w-8 w-8"></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cliente in clientes"
							v-bind:key="cliente.id"
							class="cursor-pointer"
						>
							<td>{{ cliente.nombre }} {{ cliente.apellido }}</td>
							<td>{{ cliente.country_code }} {{ cliente.telefono }}</td>
							<td>{{ cliente.correo }}</td>
							<td @click="removeCliente(cliente)">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="size-4"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M6 18 18 6M6 6l12 12"
									/>
								</svg>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<button
				v-if="clientes.length > 0"
				@click="blast()"
				class="mt-4 btn btn-primary btn-sm no-animation w-full"
			>
				Blast
			</button>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<p class="text-xl">Search</p>
			<div class="">
				<input
					class="input input-bordered input-sm w-full"
					placeholder="Buscar"
					type="text"
					v-model="searchText"
				/>
			</div>

			<div
				class="flex w-full pt-4"
				v-if="searchClientes.length > 0"
			>
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/5">Nombre</th>
							<th class="w-1/5">Teléfono</th>
							<th class="w-1/5">Correo</th>
							<th class="w-1/5">Club</th>
							<th class="w-1/5">Estatus</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cliente in searchClientes"
							v-bind:key="cliente.id"
							class="cursor-pointer"
							@click="addCliente(cliente)"
						>
							<td>{{ cliente.nombre }} {{ cliente.apellido }}</td>
							<td>{{ cliente.country_code }} {{ cliente.telefono }}</td>
							<td>{{ cliente.correo }}</td>
							<td>{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].club : '' }}</td>
							<td>
								{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].estatus : '' }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { supabase } from '../supabase'
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminCatas',
	setup() {
		const router = useRouter()

		const broadcast = ref(null)
		const template = ref(null)
		const templates = ref([])
		const clientes = ref([])
		const searchText = ref('')
		const searchClientes = ref([])

		let timeout

		let ac

		const loading = useLoading({
			loader: 'spinner'
		})

		async function getTemplates() {
			let { data } = await axios.get('/api/templates')

			return data
		}

		async function searchCliente(text) {
			ac = new AbortController()

			const terms = text.split(' ')
			let query = supabase
				.from('clientes')
				.select(
					'id, nombre, country_code, telefono, apellido, correo, excluir_blasts, suscripciones(*)'
				)
				.limit(50)
				.abortSignal(ac.signal)

			terms.forEach((term, index) => {
				const ilikeCondition = `nombre.ilike.%${term}%,apellido.ilike.%${term}%,correo.ilike.%${term}%,telefono.ilike.%${term}%`

				if (index === 0) {
					query = query.or(ilikeCondition)
				} else {
					query = query.or(ilikeCondition)
				}
			})

			const { data, error } = await query

			ac = null

			if (data) {
				return data
			} else if (error) {
				// alert("No data returned")
				return []
			}
		}

		async function executeSearch(text) {
			if (text == '') {
				return
			}

			searchCliente(text)
				.then((data) => {
					searchClientes.value = data
				})
				.catch((e) => {
					console.log('error', e)
				})
		}

		watch(searchText, async (newSearchText) => {
			if (newSearchText == '') {
				searchClientes.value = []
				if (ac) {
					ac.abort()
				}
				return
			}

			if (ac) {
				ac.abort()
			}

			clearTimeout(timeout)

			timeout = setTimeout(() => {
				executeSearch(newSearchText)
			}, 1000)
		})

		async function blast() {
			if (!broadcast.value) {
				return alert('Favor de ingresar un nombre')
			}

			if (!template.value) {
				return alert('Favor de escoger un template')
			}

			if (clientes.value.filter((c) => !c.excluir_blasts).length == 0) {
				return alert('No tienes clientes para el blast')
			}

			if (!confirm('¿Estás seguro del blast?')) {
				return
			}

			let loader = loading.show()

			let clubsToBlast = clientes.value
				.filter((c) => !c.excluir_blasts)
				.map((c) => {
					return {
						whatsappNumber: `${c.country_code.replace(/\D/g, '')}1${c.telefono}`,
						customParams: [
							{
								name: 'nombre',
								value: c.nombre
							}
						]
					}
				})

			let { status } = await axios.post('/api/blast', {
				template_name: template.value,
				broadcast_name: broadcast.value,
				receivers: clubsToBlast
			})

			loader.hide()

			console.log(status)

			if (status == 200) {
				useToast().success('Mensajes mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}

			await addMessages(template, broadcast)

			clientes.value = []
			broadcast.value = null
			template.value = null
		}

		async function addMessages(template, broadcast) {
			let mensajes = clientes.value.map((c) => {
				return {
					template: template.value,
					nombre: broadcast.value,
					cliente: c.id
				}
			})

			const { error } = await supabase.from('mensajes').upsert(mensajes)

			if (error) {
				console.log(error)
				return alert(error)
			}

			useToast().success('Clientes actualizada', { position: 'top-right' })
		}

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		onMounted(async () => {
			getTemplates().then((data) => {
				templates.value = data.messageTemplates.filter(
					(t) => t.status == 'APPROVED' && t.elementName.includes('blast')
				)
			})
		})

		function addCliente(cliente) {
			searchClientes.value = []
			searchText.value = ''

			console.log(cliente.id)

			const index = clientes.value.findIndex((item) => item.id === cliente.id)

			console.log(index)

			if (index === -1) {
				clientes.value.push(cliente)
			}
		}

		function removeCliente(cliente) {
			const index = clientes.value.findIndex((item) => item.id === cliente.id)

			if (index !== -1) {
				clientes.value.splice(index, 1)
			}
		}

		return {
			clientes,
			goToCliente,
			searchClientes,
			searchCliente,
			removeCliente,
			searchText,
			broadcast,
			template,
			templates,
			blast,
			addCliente
		}
	}
}
</script>
