<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Colecciones</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-lg">Nueva colección</p>
			<input
				class="input input-bordered input-sm w-full mt-2"
				placeholder="Nombre"
				type="text"
				v-model="nombre"
			/>

			<div class="flex flex-row w-full space-x-4 pt-4">
				<div class="w-full">
					<button
						@click="createColeccion()"
						class="btn btn-sm w-full btn-primary no-animation"
					>
						Crear colección
					</button>
				</div>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex space-x-4">
				<div class="w-full">
					<select
						v-model="activo"
						class="select select-sm select-bordered w-full"
					>
						<option value="">Estatus - Todos</option>
						<option :value="true">Estatus - Activo</option>
						<option :value="false">Estatus - Inactivo</option>
					</select>
				</div>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th>Nombre</th>
							<th>Estatus</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="coleccion in filteredColecciones"
							v-bind:key="coleccion.id"
							@click="goToColeccion(coleccion.id)"
							class="cursor-pointer"
						>
							<td>{{ coleccion.nombre }}</td>
							<td>{{ coleccion.activo ? 'Activo' : 'Inactivo' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
	name: 'Colecciones-View',
	setup() {
		const colecciones = ref([])
		const router = useRouter()
		const activo = ref('')
		const nombre = ref(null)

		async function getColecciones() {
			const { data, error } = await supabase
				.from('colecciones')
				.select('*')
				.order('nombre', { ascending: true })

			console.log(data, error)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		const filteredColecciones = computed(() => {
			return colecciones.value.filter((c) => {
				if (activo.value == '') {
					return true
				}
				return c.activo == activo.value
			})
		})

		async function createColeccion() {
			if (!nombre.value) {
				return alert('Requiere nombre')
			}
			const { data, error } = await supabase
				.from('colecciones')
				.upsert({
					nombre: nombre.value,
					activo: false
				})
				.select('*')
				.single()

			console.log(data, error)

			colecciones.value.push(data)

			nombre.value = null
		}

		function goToColeccion(coleccionId) {
			if (event.getModifierState('Meta')) {
				window.open(`/coleccion/${coleccionId}`, '_blank').focus()
			} else {
				router.push(`/coleccion/${coleccionId}`)
			}
		}

		onMounted(async () => {
			colecciones.value = await getColecciones()
		})

		return {
			activo,
			nombre,
			colecciones,
			filteredColecciones,
			createColeccion,
			goToColeccion
		}
	}
}
</script>
