<template>
	<div
		class="p-4"
		v-if="coleccion"
	>
		<div class="">
			<p class="text-2xl">Colección</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="pb-1 text-lg">Info</p>

			<div class="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-4 md:space-y-0">
				<div class="w-full md:w-3/4">
					<div class="w-full">
						<p class="pb-1 text-sm">Nombre</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Nombre"
							type="text"
							v-model="coleccion.nombre"
						/>
					</div>

					<div class="pt-4 w-full">
						<p class="pb-1 text-sm">Estatus</p>
						<div class="w-full">
							<select
								v-model="coleccion.activo"
								class="select select-sm select-bordered w-full"
							>
								<option :value="true">Activo</option>
								<option :value="false">Inactivo</option>
							</select>
						</div>
					</div>

					<div class="pt-4 w-full">
						<p class="pb-1 text-sm">Producto</p>
						<select
							v-model="producto"
							@change="addProducto(producto)"
							class="select select-sm select-bordered w-full"
						>
							<option
								selected
								disabled
								value=""
							>
								Escoger un producto
							</option>
							<option
								v-for="producto in filteredProductos"
								v-bind:key="producto.id"
								:value="producto"
							>
								{{ producto.nombre }}
							</option>
						</select>
					</div>
				</div>
				<div class="w-full md:w-1/4">
					<p class="pb-1 text-sm">Foto</p>

					<div class="aspect-video rounded-lg overflow-hidden">
						<div
							class="relative bg-contain bg-center h-full w-full"
							:style="`background-image: url(${coleccion.photo});`"
						/>
					</div>

					<!-- <div class="aspect-square rounded-lg overflow-hidden bg-gray-100">
					<img
						v-if="coleccion.photo"
						class="w-full h-full cover"
						:src="coleccion.photo"
					/>
				</div> -->

					<div class="pt-2">
						<input
							type="file"
							ref="file"
							id="product_foto"
							style="display: none"
							@change="uploadFile($event)"
						/>
						<button
							class="btn btn-outline btn-sm w-full no-animation"
							@click="$refs.file.click()"
						>
							Upload
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div
				v-if="coleccion"
				class="w-full overflow-x-scroll"
			>
				<p class="pb-1 text-lg">Productos</p>

				<table class="table table-xs mt-2">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-full">Nombre</th>
							<th class="min-w-8 w-8"></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(cP, index) in coleccion.coleccion_producto"
							v-bind:key="cP.id"
							class="cursor-pointer"
						>
							<td>{{ cP.producto.nombre }}</td>
							<td>
								<div
									class="cursor-pointer"
									@click="removeProducto(index)"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="size-4"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M6 18 18 6M6 6l12 12"
										/>
									</svg>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="flex flex-row w-full space-x-4 pt-4">
			<div class="w-full">
				<button
					@click="crearColeccionProductos()"
					class="btn btn-sm w-full btn-primary no-animation"
				>
					Actualizar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import utils from '../utils'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'

export default {
	name: 'AdminProducto',
	setup() {
		const route = useRoute()

		const coleccion = ref(null)
		const productos = ref([])

		const producto = ref('')

		let toDelete = ref([])

		const loading = useLoading({
			loader: 'spinner'
		})

		async function getColeccion() {
			const { data } = await supabase
				.from('colecciones')
				.select('*, coleccion_producto(*, producto(id, nombre))')
				.eq('id', route.params.coleccion_id)
				.single()

			console.log(data)
			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			const { data } = await supabase
				.from('productos')
				.select(
					'created_at, id, nombre, proveedor (nombre), shopify_id, shopify_handle, precio, costo, activo, tipo'
				)
				.eq('activo', true)
				.eq('tipo', 'vino')
				.order('nombre', { ascending: true })

			if (data) {
				return data.sort((a, b) => {
					const nameA = a.proveedor?.nombre || ''
					const nameB = b.proveedor?.nombre || ''
					return nameA.localeCompare(nameB)
				})
			} else {
				alert('No data returned')
			}
		}

		const filteredProductos = computed(() => {
			if (!coleccion.value) {
				return []
			}

			let exclusionIds = new Set(coleccion.value.coleccion_producto.map((cP) => cP.producto.id))

			return productos.value.filter((p) => !exclusionIds.has(p.id))
		})

		async function crearColeccionProductos() {
			let loader = loading.show()

			const clone = { ...coleccion.value }
			delete clone.coleccion_producto

			let { data, error } = await supabase.from('colecciones').upsert(clone).select()

			let cP = coleccion.value.coleccion_producto
				.filter((c) => !c.id)
				.map((c) => ({
					coleccion: coleccion.value.id,
					producto: c.producto.id,
					...(c.id && { id: c.id })
				}))

			console.log(cP)

			await supabase.from('coleccion_producto').upsert(cP, { onConflict: 'id' }).select()

			console.log(data, error)

			if (toDelete.value.length > 0) {
				const response = await supabase
					.from('coleccion_producto')
					.delete()
					.in(
						'id',
						toDelete.value.map((cP) => cP.id)
					)

				console.log(response)

				coleccion.value = await getColeccion()
				toDelete.value = []
			}

			loader.hide()
			useToast().success('Colección actualizada', { position: 'top-right' })
		}

		function addProducto(p) {
			coleccion.value.coleccion_producto.push({
				coleccion: coleccion.value.id,
				producto: {
					nombre: p.nombre,
					id: p.id
				}
			})

			producto.value = ''
		}

		function removeProducto(index) {
			if (coleccion.value.coleccion_producto[index].id) {
				toDelete.value.push(coleccion.value.coleccion_producto[index])
			}

			coleccion.value.coleccion_producto.splice(index, 1)
		}

		async function uploadFile(evt) {
			const uploadField = document.getElementById('product_foto')

			if (uploadField.files[0].size > 2097152) {
				return useToast().warning(
					'The maximum size for a photo is 2mb, please select a smaller file.',
					{ position: 'top-right' }
				)
			}

			let loader = loading.show()
			let fileName = await utils.uploadFileHandler('photos', evt)
			loader.hide()

			let url = `https://eekmegyvggirtnebrupv.supabase.co/storage/v1/object/public/${fileName}`

			coleccion.value.photo = url
		}

		onMounted(async () => {
			coleccion.value = await getColeccion()

			productos.value = await getProductos()
		})

		return {
			route,
			coleccion,
			producto,
			filteredProductos,
			crearColeccionProductos,
			addProducto,
			removeProducto,
			uploadFile
		}
	}
}
</script>
