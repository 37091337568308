<template>
	<div class="p-4">
		<p class="text-2xl">Etiquetas</p>
		<div class="grid grid-cols-1 md:grid-cols-5 gap-4 pt-4">
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Condesa</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ getUnique(3) }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Polanco</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ getUnique(4) }}
				</div>
			</div>
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Roma</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ getUnique(4) }}
				</div>
			</div>
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">San Miguel</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ getUnique(13) }}
				</div>
			</div>
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Oficina</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ getUnique(2) }}
				</div>
			</div>
		</div>

		<p class="text-2xl pt-8">Ventas</p>
		<div class="flex flex-row space-x-4 mt-4">
			<div class="w-full">
				<p class="text-sm pb-2">Inicio</p>
				<input
					class="input input-bordered input-sm w-full"
					@change="getPedidos()"
					v-model="startDate"
					type="date"
				/>
			</div>

			<div class="w-full">
				<p class="text-sm pb-2">Fin</p>
				<input
					class="input input-bordered input-sm w-full"
					@change="getPedidos()"
					v-model="endDate"
					type="date"
				/>
			</div>
		</div>

		<div class="w-full rounded-lg p-4 bg-base-100 mt-4">
			<div class="stat-title">Ventas</div>
			<div class="stat-value text-xl md:text-4xl">
				${{ total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
			</div>
			<div class="stat-actions">
				<router-link :to="'/pedidos'">
					<button class="btn btn-sm">Ver pedidos</button>
				</router-link>
			</div>
		</div>

		<div class="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4">
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Retail</div>
				<div class="stat-value text-xl md:text-4xl">
					${{
						ventas.retail.toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						})
					}}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Club</div>
				<div class="stat-value text-xl md:text-4xl">
					${{
						ventas.club.toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						})
					}}
				</div>
			</div>
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">E-com</div>
				<div class="stat-value text-xl md:text-4xl">
					${{
						ventas.ecom.toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						})
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
// import axios from "axios";
// import { useToast } from 'vue-toast-notification';
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminHome',
	setup() {
		const inventory = ref([])
		const ubicaciones = ref([])

		const startDate = ref(getToday())
		const endDate = ref(getToday())

		const total = ref(0)

		const ventas = ref({
			ecom: 0,
			club: 0,
			retail: 0
		})

		const loading = useLoading({
			loader: 'spinner'
		})

		function getToday() {
			let date = new Date()
			date.setHours(date.getHours() - 6)
			return date.toISOString().split('T')[0]
		}
		onMounted(async () => {
			let loader = loading.show()
			ubicaciones.value = await getUbicaciones()
			inventory.value = await getInventory()
			loader.hide()

			getPedidos()
		})

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('*')
				.eq('activo', true)
				.order('order', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getInventory() {
			const groupedObjects = {}

			const { data } = await supabase.rpc('total_inventory')

			data
				.filter(
					(d) =>
						(d.producto_proveedor == 'Vinos Chidos' || d.producto_proveedor == 'GluGlu') &&
						d.producto_nombre != 'Botella Club' &&
						d.producto_nombre != 'Bolsa Chida' &&
						d.producto_nombre != 'Cinta Kraft'
				)
				.forEach((producto) => {
					if (Object.prototype.hasOwnProperty.call(groupedObjects, producto.shopify_handle)) {
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock
						groupedObjects[producto.shopify_handle].total += producto.stock
					} else {
						groupedObjects[producto.shopify_handle] = {}
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock
						groupedObjects[producto.shopify_handle].total = producto.stock
					}
				})

			console.log(groupedObjects)
			return groupedObjects
		}

		function getUnique(unicacion_id) {
			let products = Object.keys(inventory.value)

			return products.filter((p) => inventory.value[p][unicacion_id] > 0).length
		}

		async function getPedidos() {
			if (!startDate.value || !endDate.value) return

			console.log(startDate, endDate)
			const { data, error } = await supabase
				.from('pedidos')
				.select('id, total, ubicacion, pedido_detalles(producto, total, cantidad)')
				.order('created_at', { ascending: false })
				.filter('created_at', 'gte', adjustDate(startDate.value))
				.filter('created_at', 'lte', adjustDate(endDate.value, 24))

			console.log('getPedidos', data, error)

			if (data) {
				total.value = data.map((p) => p.total).reduce((a, b) => a + b, 0)
				ventas.value.retail = data
					.filter((p) => [3, 4, 5, 13].includes(p.ubicacion))
					.map((p) => p.total)
					.reduce((a, b) => a + b, 0)

				let nonRetail = data.filter((p) => ![3, 4, 5, 13].includes(p.ubicacion))
				// .map((p) => p.pedido_detalles)
				// .flat()

				// let nonRetails = data
				// 	.filter((p) => ![3, 4, 5, 13].includes(p.ubicacion))
				// 	.map((p) => {
				// 		return {
				// 			id: p.id,
				// 			total: p.total,
				// 			pd_total: p.pedido_detalles
				// 				.map((p) => p.total * p.cantidad)
				// 				.reduce((a, b) => a + b, 0)
				// 		}
				// 	})

				// console.log('nonRetails', nonRetails)

				let clubs = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

				ventas.value.club = nonRetail
					.filter((p) => {
						let ids = p.pedido_detalles.map((pD) => pD.producto)

						return clubs.some((value) => ids.includes(value))
					})
					.map((p) => p.total)
					.reduce((a, b) => a + b, 0)

				ventas.value.ecom = nonRetail
					.filter((p) => {
						let ids = p.pedido_detalles.map((pD) => pD.producto)

						return !clubs.some((value) => ids.includes(value))
					})
					.map((p) => p.total)
					.reduce((a, b) => a + b, 0)
			} else {
				alert('No data returned')
			}
		}

		function adjustDate(date, hours = 0) {
			console.log(date)
			let dateToAdjust = new Date(date)
			dateToAdjust.setHours(dateToAdjust.getHours() + 6 + hours)
			return dateToAdjust.toISOString()
		}

		return {
			inventory,
			getUnique,
			startDate,
			endDate,
			getPedidos,
			total,
			ventas
		}
	}
}
</script>
