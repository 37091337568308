<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Inventario</p>

			<div class="flex justify-between gap-4">
				<div class="">
					<button
						@click="generateCSV()"
						class="btn btn-sm btn-primary no-animation"
					>
						CSV
					</button>
				</div>

				<div class="">
					<button
						@click="generateCSVShopify()"
						class="btn btn-sm btn-primary no-animation"
					>
						Shopify
					</button>
				</div>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="w-full">
				<p class="pb-2 text-sm">Proveedor</p>
				<select
					class="select select-sm select-bordered w-full"
					v-model="proveedor"
				>
					<option
						selected
						value=""
					>
						Todos
					</option>
					<option
						v-for="proveedor in proveedores"
						v-bind:key="proveedor.id"
						:value="proveedor"
					>
						{{ proveedor.nombre }}
					</option>
				</select>
			</div>

			<div
				class="flex w-full overflow-x-scroll"
				v-if="filteredProductos.length > 0"
			>
				<table class="table table-xs">
					<thead class="bg-base-100 top-0">
						<tr class="">
							<th class="min-w-32">Proveedor</th>
							<th class="min-w-48">Producto</th>
							<th
								v-for="ubicacion in ubicaciones"
								v-bind:key="ubicacion.id"
								class="min-w-20"
							>
								{{ ubicacion.nombre }}
							</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody class="">
						<tr
							v-for="producto in filteredProductos"
							v-bind:key="producto.id"
						>
							<th>{{ producto.proveedor ? producto.proveedor.nombre : '' }}</th>
							<th>{{ producto.nombre }}</th>
							<td
								v-for="ubicacion in ubicaciones"
								v-bind:key="ubicacion.id"
							>
								{{
									inventory[producto.shopify_handle]
										? inventory[producto.shopify_handle][ubicacion.id] || 0
										: 0
								}}
							</td>
							<td>
								{{
									inventory[producto.shopify_handle]
										? inventory[producto.shopify_handle].total || 0
										: 0
								}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'

export default {
	name: 'AdminInventario',
	setup() {
		// const ubicacion = ref(null)
		const ubicaciones = ref([])
		const productos = ref([])
		const proveedores = ref([])
		const inventory = ref([])
		const proveedor = ref('')

		async function getProveedores() {
			const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			const { data } = await supabase
				.from('productos')
				.select(
					'created_at, id, nombre, proveedor (nombre), shopify_id, shopify_handle, precio, costo'
				)
				.eq('activo', true)
				.order('nombre', { ascending: true })

			console.log('Productos', data)
			if (data) {
				return data.sort((a, b) =>
					(a.proveedor ? a.proveedor.nombre : '') > (b.proveedor ? b.proveedor.nombre : '')
						? 1
						: (b.proveedor ? b.proveedor.nombre : '') > (a.proveedor ? a.proveedor.nombre : '')
						? -1
						: 0
				)
			} else {
				alert('No data returned')
			}
		}

		async function getInventory() {
			const groupedObjects = {}

			const { data } = await supabase.rpc('total_inventory')

			data.forEach((producto) => {
				if (Object.prototype.hasOwnProperty.call(groupedObjects, producto.shopify_handle)) {
					groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock
					groupedObjects[producto.shopify_handle].total += producto.stock
				} else {
					groupedObjects[producto.shopify_handle] = {}
					groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock
					groupedObjects[producto.shopify_handle].total = producto.stock
				}
			})

			console.log('groupedObjects', groupedObjects)
			return groupedObjects
		}

		function generateCSVShopify() {
			let csv =
				'Handle,Title,Option1 Name,Option1 Value,Option2 Name,Option2 Value,Option3 Name,Option3 Value,SKU,HS Code,COO,Location,Incoming,Unavailable,Committed,Available,On hand\n'

			productos.value
				.filter((p) => p.shopify_handle != 'xxx')
				.forEach((p) => {
					ubicaciones.value
						.filter((u) => u.shopify == true)
						.forEach((u) => {
							let a = [p.shopify_handle, p.nombre.replaceAll(',', '')]
							let b = ['Title', 'Default Title', '', '', '', '', '', '', '']
							let c = [
								u.nombre,
								0,
								0,
								0,
								getTotal(p, u) <= 6 ? getTotal(p, u) : 6,
								`${getTotal(p, u) <= 6 ? getTotal(p, u) : 6}\n`
							]
							csv += a.concat(b).concat(c)
						})
				})

			const anchor = document.createElement('a')
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
			anchor.target = '_blank'
			anchor.download = 'shopify.csv'
			anchor.click()
		}

		function getTotal(producto, ubicacion) {
			return inventory.value[producto.shopify_handle]
				? inventory.value[producto.shopify_handle][ubicacion.id] || 0
				: 0
		}

		function generateCSV() {
			let csv = 'Proveedor, Title,' + ubicaciones.value.map((u) => u.nombre)

			filteredProductos.value.forEach((p) => {
				let a = [
					p.proveedor && p.proveedor.nombre ? `\n${p.proveedor.nombre.replaceAll(',', '')}` : '\n',
					`${p.nombre.replaceAll(',', '')}`
				]
				let b = ubicaciones.value.map((u) => getTotal(p, u))
				csv += a.concat(b)
			})

			const anchor = document.createElement('a')
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
			anchor.target = '_blank'
			anchor.download = 'inventario.csv'
			anchor.click()
		}

		const filteredProductos = computed(() => {
			if (!productos.value || productos.value.length == 0) {
				return productos
			}

			return productos.value.filter((p) => {
				if (!proveedor.value) {
					return true
				}

				return proveedor.value && p.proveedor ? p.proveedor.nombre == proveedor.value.nombre : false
			})
		})

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('*')
				.eq('activo', true)
				.order('order', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		onMounted(async () => {
			ubicaciones.value = await getUbicaciones()
			proveedores.value = await getProveedores()
			productos.value = await getProductos()
			inventory.value = await getInventory()
		})

		return {
			ubicaciones,
			productos,
			getTotal,
			generateCSV,
			generateCSVShopify,
			proveedor,
			proveedores,
			filteredProductos,
			inventory
		}
	}
}
</script>
