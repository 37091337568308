<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Pedido {{ route.params.pedido_id }}</p>
		</div>

		<div class="flex space-x-4 flex-row-reverse md:flex-row">
			<div class="w-full">
				<div class="rounded-lg p-4 bg-base-100 mt-4 w-full h-auto">
					<p class="text-xl">Productos</p>
					<table class="table table-xs mt-4">
						<thead class="sticky bg-base-100 top-0">
							<tr>
								<th class="">Producto</th>
								<th class="w-16">Cantidad</th>
								<th class="w-16">Total</th>
							</tr>
						</thead>
						<tbody
							v-for="pedido_detalle in pedido.pedido_detalles"
							v-bind:key="pedido_detalle.id"
						>
							<tr>
								<td>{{ pedido_detalle.producto?.nombre || pedido_detalle.reference }}</td>
								<td>{{ pedido_detalle.cantidad }}</td>
								<td>${{ pedido_detalle.total }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div
				class="space-y-4 w-full md:w-1/4 rounded-lg p-4 bg-base-100 mt-4"
				v-if="pedido.cliente"
			>
				<div class="w-full space-y-4">
					<div class="w-full">
						<p class="text-sm pb-1">Fecha</p>
						{{ pedido.created_at.split('T')[0] }}
					</div>

					<div class="w-full">
						<p class="text-sm pb-1">Estatus</p>
						{{ pedido.canelado ? 'Cancelado' : 'Fullfilled' }}
					</div>

					<div class="w-full">
						<p class="text-sm pb-1">Nombre</p>
						<router-link :to="`/cliente/${pedido.cliente.id}`">
							{{ pedido.cliente.nombre }} {{ pedido.cliente.apellido }}
						</router-link>
					</div>

					<div class="w-full">
						<p class="text-sm pb-1">Correo</p>
						{{ pedido.cliente.correo }}
					</div>

					<div class="w-full">
						<p class="text-sm pb-1">Ubicación</p>
						{{ pedido.ubicacion.nombre }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
// import axios from 'axios'
// import { useToast } from 'vue-toast-notification';
// import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminProducto',
	setup() {
		const route = useRoute()

		const pedido = ref({})

		async function getPedido() {
			const { data } = await supabase
				.from('pedidos')
				.select(
					'*, cliente(id, nombre, apellido, correo), ubicacion(id, nombre), pedido_detalles(cantidad, reference, producto(nombre), total)'
				)
				.eq('id', route.params.pedido_id)
				.single()

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		onMounted(async () => {
			pedido.value = await getPedido()
		})

		return {
			route,
			pedido
		}
	}
}
</script>
