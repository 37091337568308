<template>
	<div
		v-if="session || permitted()"
		class="drawer"
	>
		<input
			id="my-drawer-3"
			type="checkbox"
			class="drawer-toggle"
		/>
		<div class="drawer-content flex flex-col bg-red">
			<!-- Navbar -->
			<div class="w-full navbar bg-base-100">
				<div
					class="flex-none"
					v-if="session"
				>
					<label
						for="my-drawer-3"
						aria-label="open sidebar"
						class="btn btn-square btn-ghost"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							class="inline-block w-6 h-6 stroke-current"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M4 6h16M4 12h16M4 18h16"
							></path>
						</svg>
					</label>
				</div>
				<div class="flex-1 px-2 mx-2">chidOS</div>
				<div class="flex-none hidden lg:block">
					<ul class="menu menu-horizontal"></ul>
				</div>
			</div>
			<router-view class="bg-base-300"></router-view>
		</div>
		<div
			class="drawer-side"
			v-if="session"
		>
			<label
				for="my-drawer-3"
				aria-label="close sidebar"
				class="drawer-overlay"
			></label>
			<div class="w-80 h-full">
				<ul class="overflow-y-scroll menu p-4 w-80 min-h-full bg-base-200">
					<li @click="toggle">
						<router-link to="/"> Home </router-link>
					</li>
					<li
						v-for="section in sections"
						v-bind:key="section.name"
						class="w-full"
					>
						<details>
							<summary>{{ section.title }}</summary>
							<ul>
								<li
									v-for="link in section.links"
									v-bind:key="link.name"
									@click="toggle"
								>
									<router-link :to="{ path: link.link, force: true }">
										{{ link.name }}
									</router-link>
								</li>
							</ul>
						</details>
					</li>
					<li
						v-for="link in links"
						v-bind:key="link.name"
						@click="toggle"
					>
						<router-link :to="{ path: link.link, force: true }">
							{{ link.name }}
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<Auth v-if="session == null"></Auth>
</template>

<script>
import Auth from './components/Auth.vue'

import { onMounted, ref } from 'vue'
import { supabase } from './supabase'
import { useRoute } from 'vue-router'

export default {
	name: 'AdminHome',
	components: {
		Auth
	},
	setup() {
		const route = useRoute()

		let sections = [
			{
				title: 'Club',
				links: [
					{
						name: 'Cargos',
						link: '/cargos'
					},
					{
						name: 'Suscritores',
						link: '/suscritores'
					},
					{
						name: 'Cancelaciones',
						link: '/cancelaciones'
					},
					{
						name: 'Prospectos',
						link: '/prospectos'
					},
					{
						name: 'Catas',
						link: '/catas'
					}
				]
			},
			{
				title: 'Inventario',
				links: [
					{
						name: 'Entradas',
						link: '/entradas'
					},
					{
						name: 'Crear Entrada',
						link: '/entrada'
					},
					{
						name: 'Salidas',
						link: '/salidas'
					},
					{
						name: 'Salidas (Por Ubicación)',
						link: '/ventas'
					},
					{
						name: 'Crear Salida',
						link: '/salida'
					},
					{
						name: 'Revisar Salidas',
						link: '/revisar_salidas'
					},
					{
						name: 'Transfers',
						link: '/transfers'
					},
					{
						name: 'Crear Transfer',
						link: '/transfer'
					},
					{
						name: 'Crear Multi-transfer',
						link: '/multi_transfer'
					},
					{
						name: 'Inventario',
						link: '/inventario'
					},
					{
						name: 'Actualizar Inventario',
						link: '/actualizar_inventario'
					},
					{
						name: 'Extracciones',
						link: '/extracciones'
					}
				]
			},
			{
				title: 'Pedidos',
				links: [
					{
						name: 'Pedidos',
						link: '/pedidos'
					}
				]
			},
			{
				title: 'Clientes',
				links: [
					{
						name: 'Clientes',
						link: '/clientes'
					},
					{
						name: 'Crear Cliente',
						link: '/create_cliente'
					}
				]
			},
			{
				title: 'Productos',
				links: [
					{
						name: 'Productos',
						link: '/productos'
					},
					{
						name: 'Crear Producto',
						link: '/producto'
					},
					{
						name: 'Colecciones',
						link: '/colecciones'
					},
					{
						name: 'Crear Proveedor',
						link: '/proveedor'
					}
				]
			},
			{
				title: 'Blasts',
				links: [
					{
						name: 'Blasts',
						link: '/blasts'
					},
					{
						name: 'Crear Blast Club',
						link: '/blast'
					},
					{
						name: 'Crear Blast Custom',
						link: '/blast_custom'
					}
				]
			},
			{
				title: 'Rutas',
				links: [
					{
						name: 'Clubs',
						link: '/clubs'
					},
					{
						name: 'Ecommerce',
						link: '/rutaonline'
					}
				]
			}
		]

		let links = []

		const session = ref('')

		function toggle() {
			console.log('toggle')
			var box = document.getElementById('my-drawer-3')

			if (box) {
				box.checked = !box.checked
			}
		}

		onMounted(() => {
			console.log(route.name)
			supabase.auth.getSession().then(({ data }) => {
				session.value = data.session
			})

			supabase.auth.onAuthStateChange((_, _session) => {
				session.value = _session
			})
		})

		function permitted() {
			if (route.name == 'Salida') {
				return true
			} else if (route.name == 'Actualizar_Inventario' && route.query.retail) {
				return true
			} else if (route.name == 'Catas' && route.query.view) {
				return true
			}

			return false
		}

		return {
			permitted,
			sections,
			links,
			toggle,
			session
		}
	}
}
</script>
