<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Clientes</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-xl">Buscar</p>
			<div class="mt-4">
				<input
					class="input input-bordered input-sm w-full"
					placeholder="Buscar"
					type="text"
					v-model="searchText"
				/>
			</div>

			<div
				v-if="searchClientes.length > 0"
				class="flex w-full pt-4"
			>
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/4">Nombre</th>
							<th class="w-1/4">Correo</th>
							<th class="w-2/8">Teléfono</th>
							<th class="w-1/4">Club</th>
							<th class="w-1/4">Estatus</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cliente in searchClientes"
							v-bind:key="cliente.id"
							class="cursor-pointer hover"
							@click="goToCliente(cliente.id)"
						>
							<td>{{ cliente.nombre }}</td>
							<td>{{ cliente.correo }}</td>
							<td>{{ cliente.telefono }}</td>
							<td>{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].club : '' }}</td>
							<td>
								{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].estatus : '' }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default {
	name: 'ClientesList',
	setup() {
		const clientes = ref([])
		const searchClientes = ref([])
		const searchText = ref('')

		const router = useRouter()

		let timeout

		let ac

		async function searchCliente(text) {
			ac = new AbortController()

			const terms = text.split(' ')
			let query = supabase.from('clientes').select('*, suscripciones(*)')

			terms.forEach((term, index) => {
				const ilikeCondition = `nombre.ilike.%${term}%,apellido.ilike.%${term}%,correo.ilike.%${term}%,telefono.ilike.%${term}%`

				if (index === 0) {
					query = query.or(ilikeCondition)
				} else {
					query = query.or(ilikeCondition)
				}
			})

			const { data, error } = await query

			console.log(data, error)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function executeSearch(text) {
			searchCliente(text).then((data) => {
				searchClientes.value = data
			})
		}

		watch(searchText, async (newSearchText, oldSearchText) => {
			if (newSearchText == '') {
				searchClientes.value = clientes.value
				if (ac) {
					ac.abort()
				}
				return
			}

			console.log(newSearchText, oldSearchText)

			if (ac) {
				ac.abort()
			}

			clearTimeout(timeout)

			timeout = setTimeout(() => {
				executeSearch(newSearchText)
			}, 1000)
		})

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		onMounted(async () => {})

		return {
			clientes,
			searchCliente,
			searchClientes,
			searchText,
			goToCliente
		}
	}
}
</script>
