<template>
	<div class="p-4">
		<p class="text-2xl">Crear Multi-transfer</p>
		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="w-full flex space-x-4">
				<div class="w-full">
					<select
						v-model="from"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Ubicación desde
						</option>
						<option
							v-for="ubicacion in ubicaciones"
							v-bind:key="ubicacion.id"
							:value="ubicacion"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>
			</div>
			<div
				class="w-full flex space-x-4"
				v-if="from"
			>
				<select
					v-model="producto"
					@change="addProducto(producto)"
					class="select select-sm select-bordered w-full"
				>
					<option
						selected
						disabled
						value=""
					>
						Escoger una producto
					</option>
					<option
						v-for="producto in productos.filter(
							(p) => !transfers.map((e) => e.producto).find((e) => e == p.producto)
						)"
						v-bind:key="producto.producto"
						:value="producto"
					>
						{{ producto.producto_nombre }}
					</option>
				</select>

				<div class="w-full">
					<input
						class="input input-bordered input-sm w-full"
						placeholder="Filtrar"
						type="text"
						v-model="filter"
					/>
				</div>
			</div>
			<div>
				<div
					v-if="filteredProducts.length > 0"
					class="p-2 mt-4 max-h-60 overflow-scroll z-50 border rounded-lg text-sm"
				>
					<div
						class="p-1 cursor-pointer"
						@click="addProducto(filteredProduct)"
						v-for="filteredProduct in filteredProducts.filter(
							(p) => !transfers.map((e) => e.producto).find((e) => e == p.producto)
						)"
						v-bind:key="filteredProduct.id"
					>
						{{ filteredProduct.producto_nombre }}
					</div>
				</div>
			</div>

			<div class="flex w-full overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-48 min-w-48">Producto</th>
							<th class="w-16 min-w-16">Total</th>
							<th
								v-for="ubicacion in ubicaciones.filter((u) => u.nombre != from.nombre)"
								v-bind:key="ubicacion.id"
								class="w-24 min-w-24"
							>
								{{ ubicacion.nombre }}
							</th>
							<th class="w-8 min-w-8"></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="transfer in transfers"
							v-bind:key="transfer.id"
						>
							<td>{{ transfer.producto_nombre }}</td>
							<td>{{ getTotal(transfer, from) }}</td>
							<td
								v-for="ubicacion in ubicaciones.filter((u) => u.nombre != from.nombre)"
								v-bind:key="ubicacion.id"
							>
								<input
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="number"
									v-model="transfer[`cantidad_${ubicacion.nombre.toLowerCase()}`]"
									min="0"
									:max="getRemaining(transfer, from, ubicacion)"
								/>
							</td>
							<td
								class="w-10 cursor-pointer"
								@click="removeProduct(transfer)"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="size-4"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M6 18 18 6M6 6l12 12"
									/>
								</svg>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="pt-8">
				<button
					@click="crearEntradas()"
					class="btn btn-primary btn-sm no-animation w-full"
				>
					Crear
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminMultiTransfer',
	setup() {
		const from = ref('')
		const to = ref('')
		const ubicaciones = ref([])
		const productos = ref([])
		const producto = ref('')
		const transfers = ref([])
		const filter = ref(null)
		const loading = useLoading({
			loader: 'spinner'
		})

		let loader

		function getTotal(transfer, ubicacion) {
			return (
				transfer[ubicacion.nombre] -
				ubicaciones.value
					.map((u) => transfer[`cantidad_${u.nombre.toLowerCase()}`] ?? 0)
					.reduce((prev, next) => prev + next)
			)
		}

		function getRemaining(transfer, from_ubicacion, to_ubicacion) {
			return (
				transfer[from_ubicacion.nombre] -
				ubicaciones.value
					.map((u) => transfer[`cantidad_${u.nombre.toLowerCase()}`] ?? 0)
					.reduce((prev, next) => prev + next) +
				(transfer[`cantidad_${to_ubicacion.nombre.toLowerCase()}`] ?? 0)
			)
		}

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.order('order', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			const { data } = await supabase.rpc('get_total_inventory')
			return data.sort((a, b) => {
				const aValue = a.producto_proveedor ?? ''
				const bValue = b.producto_proveedor ?? ''

				return aValue.localeCompare(bValue)
			})
		}

		function addProducto(filteredProducto) {
			producto.value = filteredProducto
			if (!transfers.value.find((e) => e.id == producto.value.producto)) {
				transfers.value.push(Object.assign({}, producto.value))
			}

			producto.value = ''
			filter.value = ''
		}

		function removeProduct(transfer) {
			transfers.value = transfers.value.filter((e) => e.producto != transfer.producto)
		}

		async function crearEntradas() {
			if (!from.value) {
				return alert('Escoger tus ubicaciones')
			}

			if (transfers.value.length == 0) {
				return alert('Escoger un producto para transferir')
			}

			let transfers_to_create_with_entradas_salidas = ubicaciones.value.map((u) => {
				return {
					from: from.value.id,
					to: u.id,
					entradas: transfers.value
						.filter(
							(t) =>
								t[`cantidad_${u.nombre.toLowerCase()}`] &&
								t[`cantidad_${u.nombre.toLowerCase()}`] > 0
						)
						.map((t) => {
							return {
								producto: t.producto,
								ubicacion: u.id,
								cantidad: t[`cantidad_${u.nombre.toLowerCase()}`]
							}
						}),
					salidas: transfers.value
						.filter(
							(t) =>
								t[`cantidad_${u.nombre.toLowerCase()}`] &&
								t[`cantidad_${u.nombre.toLowerCase()}`] > 0
						)
						.map((t) => {
							return {
								producto: t.producto,
								ubicacion: from.value.id,
								cantidad: t[`cantidad_${u.nombre.toLowerCase()}`]
							}
						})
				}
			})

			console.log(
				'transfers_to_create_with_entradas_salidas',
				transfers_to_create_with_entradas_salidas
			)

			let t = transfers_to_create_with_entradas_salidas
				.filter((t) => t.entradas.length > 0 && t.salidas.length > 0)
				.map((t, i) => {
					console.log(i)
					return {
						from: t.from,
						to: t.to
						// id: i //FOR TESTING
					}
				})

			console.log('t', t)

			// const data = t

			loader = loading.show()

			const { data, error } = await supabase.from('transfers').upsert(t).select()
			console.log(data, error)

			let e = transfers_to_create_with_entradas_salidas
				.filter((t) => t.entradas.length > 0 && t.salidas.length > 0)
				.map((t) => {
					let transfer_created = data.find((d) => d.from == t.from && d.to == t.to)

					console.log('transfer_created', transfer_created, transfer_created.id)

					return t.entradas.map((e) => {
						return {
							producto: e.producto,
							ubicacion: t.to,
							cantidad: e.cantidad,
							transfer: transfer_created.id
						}
					})
				})
				.flat()

			console.log(e)

			let s = transfers_to_create_with_entradas_salidas
				.filter((t) => t.entradas.length > 0 && t.salidas.length > 0)
				.map((t) => {
					let transfer_created = data.find((d) => d.from == t.from && d.to == t.to)

					console.log('transfer_created', transfer_created, transfer_created.id)

					return t.salidas.map((e) => {
						return {
							producto: e.producto,
							ubicacion: t.from,
							cantidad: e.cantidad,
							transfer: transfer_created.id
						}
					})
				})
				.flat()

			console.log(s)

			await supabase.from('entradas').insert(e)
			await supabase.from('salidas').insert(s)

			loader.hide()

			transfers.value = []
			from.value = ''
			refreshProductos()
		}

		async function refreshProductos() {
			productos.value = await getProductos()
		}

		const filteredProducts = computed(() => {
			return productos.value.filter((p) => {
				if (!filter.value) {
					return false
				}

				return p.producto_nombre.toLowerCase().includes(filter.value.toLowerCase())
			})
		})

		onMounted(async () => {
			ubicaciones.value = await getUbicaciones()
			productos.value = await getProductos()
		})

		return {
			from,
			to,
			ubicaciones,
			productos,
			producto,
			transfers,
			addProducto,
			removeProduct,
			crearEntradas,
			getTotal,
			getRemaining,
			filter,
			filteredProducts
		}
	}
}
</script>
