<template>
	<div class="p-4">
		<p class="text-2xl">Crear {{ type }}</p>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<select
				v-if="type == 'salida'"
				v-model="referenceType"
				@change="setType()"
				class="w-full select select-sm select-bordered"
			>
				<option
					selected
					disabled
					:value="null"
				>
					Escoger el tipo de salida
				</option>
				<option
					selected
					value=""
				>
					{{ type.charAt(0).toUpperCase() + type.slice(1) }}
				</option>
				<option
					selected
					value="Vino de la Semana"
				>
					Vino de la Semana
				</option>
				<option
					selected
					value="Cata"
				>
					Cata
				</option>
			</select>

			<div :class="{ hidden: type == 'salida' && referenceType == null }">
				<div class="w-full">
					<select
						v-model="ubicacion"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Escoger una ubicación
						</option>
						<option
							v-for="ubicacion in ubicaciones.filter((u) => {
								if (referenceType == 'Vino de la Semana') {
									let list = ['Roma', 'Polanco', 'Condesa', 'San Miguel']
									return list.includes(u.nombre)
								} else if (referenceType == 'Cata') {
									let list = ['SE - Roma', 'SE - Polanco', 'SE - Condesa']
									return list.includes(u.nombre)
								} else {
									return true
								}
							})"
							v-bind:key="ubicacion.id"
							:value="ubicacion.id"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>

				<div class="pt-4">
					<input
						class="input input-bordered input-sm w-full"
						placeholder="Referencia"
						type="text"
						v-model="reference"
					/>
				</div>

				<div class="pt-4 w-full flex space-x-4">
					<select
						:disabled="prefill && entradas.length > 0"
						v-model="producto"
						@change="addProducto(producto)"
						class="w-1/2 select select-sm select-bordered"
					>
						<option
							selected
							disabled
							value=""
						>
							Escoger una producto
						</option>
						<option
							v-for="producto in productos.filter(
								(p) => !entradas.map((e) => e.id).find((e) => e == p.id)
							)"
							v-bind:key="producto.id"
							:value="producto"
						>
							{{ producto.nombre }}
						</option>
					</select>

					<div
						v-if="!prefill"
						class="w-1/2"
					>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Filtrar"
							type="text"
							v-model="filter"
						/>
					</div>
				</div>

				<div
					v-if="!prefill && filteredProducts.length > 0"
					class="p-2 mt-4 max-h-60 overflow-scroll z-50 border rounded-lg text-sm"
				>
					<div
						class="p-1 cursor-pointer"
						@click="addProducto(filteredProduct)"
						v-for="filteredProduct in filteredProducts"
						v-bind:key="filteredProduct.id"
					>
						{{ filteredProduct.nombre }}
					</div>
				</div>

				<div class="flex w-full pt-4">
					<table class="table table-xs">
						<thead class="">
							<tr>
								<th class="w-full">Producto</th>
								<th
									v-if="type == 'salida'"
									class="min-w-32 w-32"
								>
									Total
								</th>
								<th class="min-w-32 w-32">{{ type.charAt(0).toUpperCase() + type.slice(1) }}</th>
								<th class="min-w-8 w-8"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="entrada in entradas"
								v-bind:key="entrada.id"
							>
								<td>{{ entrada.nombre }}</td>
								<td v-if="type == 'salida'">{{ getTotal(entrada) }}</td>
								<td>
									<input
										:disabled="prefill"
										class="input input-bordered input-sm w-full"
										placeholder=""
										type="number"
										@change="checkMax(entrada)"
										:max="getTotal(entrada)"
										v-model="entrada.cantidad"
									/>
								</td>
								<td
									class="w-10 cursor-pointer"
									@click="removeProduct(entrada)"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="size-4"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M6 18 18 6M6 6l12 12"
										/>
									</svg>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="pt-4">
					<button
						@click="crearEntradas()"
						class="btn btn-primary btn-sm no-animation w-full"
					>
						Crear
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toast-notification'

export default {
	name: 'AdminEntradaSalida',
	setup() {
		console.log('setup')
		const type = ref('')
		const prefill = ref(false)
		const route = useRoute()
		const ubicacion = ref('')
		const ubicaciones = ref(['Todos'])
		const productos = ref([])
		const producto = ref('')
		const entradas = ref([])
		const reference = ref('')
		const filter = ref(null)
		const referenceType = ref(null)

		function getTotal(producto) {
			if (type.value == 'entrada') {
				return 99999
			}

			return (
				getEntradas(producto, ubicacion.value) -
				getSalidas(producto, ubicacion.value) +
				getAjustes(producto, ubicacion.value)
			)
		}

		function getSalidas(producto, ubicacion) {
			let salidas = producto.salidas.filter((a) => a.ubicacion == ubicacion)
			return salidas.length > 0
				? salidas.map((s) => s.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getEntradas(producto, ubicacion) {
			let entradas = producto.entradas.filter((a) => a.ubicacion == ubicacion)
			return entradas.length > 0
				? entradas.map((e) => e.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getAjustes(producto, ubicacion) {
			let ajustes = producto.ajustes.filter((a) => a.ubicacion == ubicacion)
			return ajustes.length > 0
				? ajustes.map((a) => a.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function checkMax(entrada) {
			if (type.value == 'entrada') {
				return
			}

			if (entrada.cantidad > getTotal(entrada)) {
				entrada.cantidad = getTotal(entrada)
			}
		}

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq('activo', true)
				.order('order', { ascending: true })

			if (data) {
				if (prefill.value) {
					return data.filter(
						(u) =>
							u.nombre != 'Bodega' && u.nombre != 'Oficina' && u.nombre != 'Tasting Room - Roma'
					)
				}

				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			const { data } = await supabase
				.from('productos')
				.select('id, nombre, salidas (*), entradas (*), ajustes (*), proveedor(nombre)')
				.eq('activo', true)
				.order('nombre', { ascending: true })

			if (data) {
				return data.sort((a, b) =>
					(a.proveedor ? a.proveedor.nombre : '') > (b.proveedor ? b.proveedor.nombre : '')
						? 1
						: (b.proveedor ? b.proveedor.nombre : '') > (a.proveedor ? a.proveedor.nombre : '')
						? -1
						: 0
				)
			} else {
				alert('No data returned')
			}
		}

		function addProducto(filteredProducto) {
			producto.value = filteredProducto

			if (!entradas.value.find((e) => e.id == producto.value.id)) {
				let entrada = Object.assign({}, producto.value)

				if (referenceType.value == 'Vino de la Semana' || referenceType.value == 'Cata') {
					entrada.cantidad = 1
				}

				entradas.value.unshift(entrada)
			}

			producto.value = ''
			filter.value = ''
		}

		function removeProduct(producto) {
			entradas.value = entradas.value.filter((e) => e.id != producto.id)
		}

		async function crearEntradas() {
			if (!ubicacion.value) {
				return alert('Escoger una ubicación')
			}

			if (!entradas.value || entradas.value.length == 0) {
				return alert('Escoger mínimo un producto')
			}

			if (entradas.value.filter((e) => !e.cantidad || e.cantidad == 0).length > 0) {
				return alert('Escoger una cantidad arriba de 0')
			}

			let e = entradas.value.map((e) => {
				return {
					producto: e.id,
					ubicacion: ubicacion.value,
					cantidad: e.cantidad,
					reference: reference.value || referenceType.value
				}
			})

			let { error } = await supabase.from(type.value.concat('s')).upsert(e)

			if (error) {
				return alert(error.details)
			}

			let text = type.value.charAt(0).toUpperCase() + type.value.slice(1) + ' registrada'
			useToast().success(text, { position: 'top-right' })

			entradas.value = []
			ubicacion.value = ''
			reference.value = ''
			referenceType.value = null
		}

		const filteredProducts = computed(() => {
			return productos.value.filter((p) => {
				if (!filter.value) {
					return false
				}

				return p.nombre.toLowerCase().includes(filter.value.toLowerCase())
			})
		})

		onMounted(async () => {
			console.log('mounted')
			if (route.path.includes('entrada')) {
				type.value = 'entrada'
			} else {
				type.value = 'salida'
			}

			// if (route.query.prefill) {
			//   reference.value = "Vino de la semana"
			//   prefill.value = true
			// }

			ubicaciones.value = await getUbicaciones()
			productos.value = await getProductos()
		})

		function setType() {
			reference.value = null
			ubicacion.value = ''
			entradas.value = []
		}

		return {
			type,
			ubicacion,
			ubicaciones,
			productos,
			producto,
			entradas,
			addProducto,
			removeProduct,
			crearEntradas,
			reference,
			checkMax,
			getTotal,
			prefill,
			filter,
			filteredProducts,
			referenceType,
			setType
		}
	}
}
</script>
