<template>
	<div class="p-4">
		<div class="block space-y-4 sm:flex sm:space-y-0 flex-row justify-between">
			<p class="text-2xl">Tienda en línea</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div
				role="tablist"
				class="tabs tabs-boxed w-full"
			>
				<a
					@click="filterCondition = 'buzon'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'buzon' ? 'tab-active' : '']"
					>Bucón</a
				>
				<a
					@click="filterCondition = 'local'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'local' ? 'tab-active' : '']"
					>Local</a
				>
				<a
					@click="filterCondition = 'foraneo'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'foraneo' ? 'tab-active' : '']"
					>Foráneo</a
				>
				<a
					@click="filterCondition = 'pickup'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'pickup' ? 'tab-active' : '']"
					>Pickup</a
				>
				<a
					@click="filterCondition = 'todo'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'todo' ? 'tab-active' : '']"
					>Todo</a
				>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/12">Fecha</th>
							<th class="w-1/12">Shopify ID</th>
							<!-- <th class="w-2/12">Producto</th> -->
							<th class="w-2/12">Cliente</th>
							<th class="w-1/12">Entgrega</th>
							<th class="w-2/12">Notas</th>
							<th class="w-3/12">Accion</th>
						</tr>
					</thead>
					<tbody
						v-for="pedido in pedidosFiltrados"
						v-bind:key="pedido.id"
					>
						<tr>
							<td>{{ pedido.created_at.split('T')[0] }}</td>
							<td>{{ pedido.shopify_id }}</td>
							<!-- <td>{{pedido_detalle.producto.nombre}}</td>		 -->
							<td
								v-if="pedido.cliente"
								class="cursor-pointer"
								@click="goToCliente(pedido.cliente.id)"
							>
								{{ pedido.cliente.nombre }} {{ pedido.cliente.apellido }}
							</td>
							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.delivery_type_copy }}</p>
								<select
									v-else
									v-model="pedido.delivery_type_copy"
									class="select select-sm select-bordered w-full"
								>
									<option
										selected
										disabled
										:value="null"
									>
										Escoger
									</option>
									<option value="local">Local</option>
									<option value="foraneo">Foráneo</option>
									<option value="pickup">Pickup</option>
									<option value="sin_entrega">Sin entrega</option>
								</select>
							</td>

							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.notas }}</p>
								<input
									v-if="edit[pedido.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="pedido.notas"
								/>
							</td>
							<td>
								<div class="flex flex-row space-x-4 w-full">
									<div
										v-if="filterCondition == 'buzon'"
										class="w-full"
									>
										<button
											@click="mandarUpsell(pedido)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Mandar upsell
										</button>
									</div>
									<div
										v-if="filterCondition == 'buzon'"
										class="w-full"
									>
										<button
											@click="marcarListo(pedido, true)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Marcar listo
										</button>
									</div>
									<div
										v-if="filterCondition == 'local'"
										class="w-full"
									>
										<button
											@click="mandarOnfleet(pedido, pedido_detalle)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Mandar onfleet
										</button>
									</div>
									<div
										v-if="filterCondition == 'foraneo'"
										class="w-full"
									>
										<button
											@click="marcarEnviado(pedido)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Marcar enviado
										</button>
									</div>
									<div
										v-if="filterCondition == 'pickup'"
										class="w-full"
									>
										<button
											@click="marcarEnviado(pedido)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Marcar enviado
										</button>
									</div>
									<div
										v-if="filterCondition == 'todo'"
										class="w-full"
									>
										<button
											@click="marcarListo(pedido, null)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Regresar
										</button>
									</div>
									<div
										v-if="!edit[pedido.id]"
										class="w-full"
									>
										<button
											@click="edit[pedido.id] = true"
											class="btn btn-primary btn-sm no-animation w-full"
										>
											Editar
										</button>
									</div>
									<div
										v-if="edit[pedido.id]"
										class="w-full"
									>
										<button
											@click="updatePedido(pedido)"
											class="btn btn-primary btn-sm no-animation w-full"
										>
											Guardar
										</button>
									</div>

									<div
										v-if="edit[pedido.id]"
										class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer"
										@click="cancelEdit(pedido)"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6 w-8"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M6 18 18 6M6 6l12 12"
											/>
										</svg>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminSalidas',
	setup() {
		const router = useRouter()

		const pedidos = ref([])
		const pedidosCopy = ref([])

		const loading = useLoading({
			loader: 'spinner'
		})

		let filterCondition = ref('buzon')

		let edit = ref({})

		async function getPedidos() {
			const productIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

			const { data, error } = await supabase
				.from('pedidos')
				.select(
					'*, cliente(id, nombre, apellido, correo, country_code, telefono, direccion, suscripciones(*)), pedido_detalles!inner(producto!inner(*)))'
				)
				.not('pedido_detalles.producto.id', 'in', `(${productIds.join(',')})`)
				.or('delivery_type.is.null,delivery_type.neq.sin_entrega')
				.order('created_at', { ascending: false })
				.not('cliente', 'is', null)
				.eq('cancelado', false)
				.eq('ubicacion', 2)

			console.log(data, error)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		function clubCount(filter) {
			return getPedidosForFilter(filter).length
		}

		function getPedidosForFilter(filter) {
			return pedidos.value.filter((p) => {
				switch (filter) {
					case 'buzon':
						return !p.delivery_ready
					case 'local':
						return p.delivery_ready == true && p.delivery_type == 'local' && !p.enviado
					case 'foraneo':
						return p.delivery_ready == true && p.delivery_type == 'foraneo' && !p.enviado
					case 'pickup':
						return p.delivery_ready == true && p.delivery_type == 'pickup' && !p.enviado
					default:
						return true
				}
			})
		}

		const pedidosFiltrados = computed(() => {
			return getPedidosForFilter(filterCondition.value)
		})

		function marcarListo(pedido, status) {
			if (!pedido.delivery_type) {
				return alert('Escoger un tipo de entrega')
			}

			if (edit.value[pedido.id]) {
				return alert('Primero, guarda tus cambios')
			}

			pedido.delivery_ready = status

			if (status == null) {
				pedido.enviado = null
			}

			updatePedido(pedido)
		}

		function marcarEnviado(pedido) {
			pedido.enviado = true
			updatePedido(pedido)
		}

		async function updatePedido(pedido) {
			if (!confirm('¿Quieres guardar tus cambios?')) {
				return cancelEdit(pedido)
			}

			let loader = loading.show()

			pedido.delivery_type = pedido.delivery_type_copy

			const { error } = await supabase
				.from('pedidos')
				.update({
					notas: pedido.notas,
					delivery_ready: pedido.delivery_ready,
					delivery_type: pedido.delivery_type,
					enviado: pedido.enviado
				})
				.eq('id', pedido.id)

			console.log(error)
			if (error) {
				return alert(error)
			}

			pedidosCopy.value = structuredClone(pedidos.value)

			useToast().success('Pedido actualizada', { position: 'top-right' })

			loader.hide()

			edit.value[pedido.id] = false
		}

		async function mandarOnfleet(pedido) {
			let loader = loading.show()
			axios
				.post('/api/onfleet', {
					cliente: pedido.cliente,
					producto: pedido.pedido_detalles.map((pd) => pd.producto.nombre).join(', ')
				})
				.then(async (res) => {
					const { error } = await supabase
						.from('pedidos')
						.update({ enviado: true })
						.eq('id', pedido.id)

					if (error) {
						throw error
					} else {
						pedido.enviado = true
					}

					return res
				})
				.then((res) => {
					alert(
						`chidOS: ${JSON.stringify(
							res.data.task.destination.address
						)}\n\nOnfleet: ${JSON.stringify(res.data.data.destination.address)}`
					)
					loader.hide()
					useToast().success('Éxito', { position: 'top-right' })
				})
				.catch((e) => {
					console.log(e)

					try {
						alert(e.response.data.message.cause)
					} catch (e) {
						alert('Hubo un error creando el task en Onfleet')
					}

					loader.hide()
					useToast().error('No jaló', { position: 'top-right' })
				})
		}

		function cancelEdit(pedido) {
			edit.value[pedido.id] = false
			pedidos.value = structuredClone(pedidosCopy.value)
		}

		async function mandarUpsell(pedido) {
			if (!pedido.delivery_type) {
				return alert('Escoger un tipo de entrega')
			}

			if (!confirm('¿Quieres mandar el mensaje?')) {
				return cancelEdit(pedido)
			}

			let loader = loading.show()

			let template

			switch (pedido.delivery_type) {
				case 'local':
					template = 'upsell_local_v3'
					break
				case 'foraneo':
					template = 'upsell_foraneo_v3'
					break
				case 'pickup':
					template = 'upsell_pickup_v2'
					break
				default:
					loader.hide()
					return alert('Primero escoge una entrega')
			}

			let { status } = await axios.post('/api/blast', {
				template_name: template,
				broadcast_name: `upsell_${pedido.shopify_id}`,
				receivers: [
					{
						whatsappNumber: `${pedido.cliente.country_code.replace(/\D/g, '')}1${
							pedido.cliente.telefono
						}`,
						customParams: [
							{
								name: 'nombre',
								value: pedido.cliente.nombre
							},
							{
								name: 'apellido',
								value: pedido.cliente.apellido
							},
							{
								name: 'correo',
								value: pedido.cliente.correo
							}
						]
					}
				]
			})

			loader.hide()

			if (status == 200) {
				useToast().success('Mensaje mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}

			await addMessage({
				template: template,
				nombre: 'Upsell',
				cliente: pedido.cliente.id
			})
		}

		async function addMessage(mensaje) {
			const { error } = await supabase.from('mensajes').insert(mensaje)

			if (error) {
				console.log(error)
				return alert(error)
			}

			useToast().success('Cliente actualizada', { position: 'top-right' })
		}

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		onMounted(async () => {
			getPedidos().then((data) => {
				pedidos.value = data.map((p) => {
					p.delivery_type_copy = p.delivery_type
					return p
				})
				pedidosCopy.value = JSON.parse(JSON.stringify(data))
			})
		})

		return {
			pedidos,
			marcarListo,
			marcarEnviado,
			mandarUpsell,
			mandarOnfleet,
			pedidosFiltrados,
			edit,
			updatePedido,
			cancelEdit,
			filterCondition,
			clubCount,
			goToCliente
		}
	}
}
</script>
