<template>
	<div class="p-4">
		<p class="text-2xl">Crear Transfer</p>
		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="w-full flex space-x-4">
				<div class="w-full">
					<select
						v-model="from"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Ubicación desde
						</option>
						<option
							v-for="ubicacion in ubicaciones"
							v-bind:key="ubicacion.id"
							:value="ubicacion.id"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>
				<div class="w-full">
					<select
						v-model="to"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Ubicación hacia
						</option>
						<option
							v-for="ubicacion in ubicaciones.filter((u) => u.id != from)"
							v-bind:key="ubicacion.id"
							:value="ubicacion.id"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>
			</div>

			<div class="w-full flex space-x-4">
				<select
					v-model="producto"
					@change="addProducto(producto)"
					class="w-1/2 select select-sm select-bordered"
				>
					<option
						selected
						disabled
						value=""
					>
						Escoger una producto
					</option>
					<option
						v-for="producto in productos.filter(
							(p) => !transfers.map((e) => e.id).find((e) => e == p.id)
						)"
						v-bind:key="producto.id"
						:value="producto"
					>
						{{ producto.nombre }}
					</option>
				</select>

				<div class="w-1/2">
					<input
						class="input input-bordered input-sm w-full"
						placeholder="Filtrar"
						type="text"
						v-model="filter"
					/>
				</div>
			</div>

			<div
				v-if="filteredProducts.length > 0"
				class="p-2 mt-4 max-h-60 overflow-scroll z-50 border rounded text-sm"
			>
				<div
					class="p-1 cursor-pointer"
					@click="addProducto(filteredProduct)"
					v-for="filteredProduct in filteredProducts"
					v-bind:key="filteredProduct.id"
				>
					{{ filteredProduct.nombre }}
				</div>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="flex w-full overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-full">Producto</th>
							<th class="min-w-32 w-32">Total</th>
							<th class="min-w-32 w-32">Transfer</th>
							<th class="min-w-8 w-8"></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="transfer in transfers"
							v-bind:key="transfer.id"
						>
							<td>{{ transfer.nombre }}</td>
							<td>{{ getTotal(transfer) }}</td>
							<td>
								<input
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="number"
									@change="checkMax(transfer)"
									v-model="transfer.cantidad"
									:max="getTotal(transfer)"
								/>
							</td>
							<td
								class="w-10 cursor-pointer"
								@click="removeProduct(transfer)"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="size-4"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M6 18 18 6M6 6l12 12"
									/>
								</svg>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="">
				<button
					@click="crearEntradas()"
					class="btn btn-primary btn-sm no-animation w-full"
				>
					Crear
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminTransfer',
	setup() {
		const from = ref('')
		const to = ref('')
		const ubicaciones = ref(['Todos'])
		const productos = ref([])
		const producto = ref('')
		const transfers = ref([])
		const filter = ref(null)
		const loading = useLoading({
			loader: 'spinner'
		})

		let loader

		function getTotal(producto) {
			return (
				getEntradas(producto, from.value) -
				getSalidas(producto, from.value) +
				getAjustes(producto, from.value)
			)
		}

		function getSalidas(producto, ubicacion) {
			let salidas = producto.salidas.filter((a) => a.ubicacion == ubicacion)
			return salidas.length > 0
				? salidas.map((s) => s.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getEntradas(producto, ubicacion) {
			let entradas = producto.entradas.filter((a) => a.ubicacion == ubicacion)
			return entradas.length > 0
				? entradas.map((e) => e.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getAjustes(producto, ubicacion) {
			let ajustes = producto.ajustes.filter((a) => a.ubicacion == ubicacion)
			return ajustes.length > 0
				? ajustes.map((a) => a.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function checkMax(entrada) {
			if (entrada.cantidad > getTotal(entrada)) {
				entrada.cantidad = getTotal(entrada)
			}
		}

		async function getUbicaciones() {
			const { data } = await supabase.from('ubicaciones').select('id, nombre')

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			const { data } = await supabase
				.from('productos')
				.select('id, nombre, salidas (*), entradas (*), ajustes (*), proveedor(nombre)')
				.eq('activo', true)
				.order('nombre', { ascending: true })

			if (data) {
				return data.sort((a, b) =>
					(a.proveedor ? a.proveedor.nombre : '') > (b.proveedor ? b.proveedor.nombre : '')
						? 1
						: (b.proveedor ? b.proveedor.nombre : '') > (a.proveedor ? a.proveedor.nombre : '')
						? -1
						: 0
				)
			} else {
				alert('No data returned')
			}
		}

		function addProducto(filteredProduct) {
			producto.value = filteredProduct

			if (!transfers.value.find((e) => e.id == producto.value.id)) {
				transfers.value.push(Object.assign({}, producto.value))
			}

			producto.value = ''
			filter.value = ''
		}

		function removeProduct(producto) {
			transfers.value = transfers.value.filter((e) => e.id != producto.id)
		}

		async function crearEntradas() {
			if (!from.value || !to.value) {
				return alert('Escoger tus ubicaciones')
			}

			if (transfers.value.length == 0) {
				return alert('Escoger un producto para transferir')
			}

			if (transfers.value.filter((e) => !e.cantidad || e.cantidad == 0).length > 0) {
				return alert('Faltan cantidades')
			}

			loader = loading.show()

			const { data } = await supabase
				.from('transfers')
				.upsert({
					from: from.value,
					to: to.value
				})
				.select()
				.single()

			let e = transfers.value.map((e) => {
				return {
					producto: e.id,
					ubicacion: to.value,
					cantidad: e.cantidad,
					transfer: data.id
				}
			})

			let s = transfers.value.map((e) => {
				return {
					producto: e.id,
					ubicacion: from.value,
					cantidad: e.cantidad,
					transfer: data.id
				}
			})

			loader.hide()

			await supabase.from('entradas').insert(e)
			await supabase.from('salidas').insert(s)

			transfers.value = []
			from.value = ''
			to.value = ''
			refreshProductos()
		}

		async function refreshProductos() {
			productos.value = await getProductos()
		}

		const filteredProducts = computed(() => {
			return productos.value.filter((p) => {
				if (!filter.value) {
					return false
				}

				return p.nombre.toLowerCase().includes(filter.value.toLowerCase())
			})
		})

		onMounted(async () => {
			ubicaciones.value = await getUbicaciones()
			productos.value = await getProductos()
		})

		return {
			from,
			to,
			ubicaciones,
			productos,
			producto,
			transfers,
			addProducto,
			removeProduct,
			crearEntradas,
			getTotal,
			checkMax,
			filter,
			filteredProducts
		}
	}
}
</script>
