<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Clientes</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-xl">Buscar</p>
			<div class="mt-4">
				<input
					class="input input-bordered input-sm w-full"
					placeholder="Buscar"
					type="text"
					v-model="searchText"
				/>
			</div>

			<div
				v-if="searchClientes.length > 0"
				class="flex w-full pt-4"
			>
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/4">Nombre</th>
							<th class="w-1/4">Correo</th>
							<th class="w-2/8">Teléfono</th>
							<th class="w-1/4">Club</th>
							<th class="w-1/4">Estatus</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cliente in searchClientes"
							v-bind:key="cliente.id"
							class="cursor-pointer hover"
							@click="goToCliente(cliente.id)"
						>
							<td>{{ cliente.nombre }}</td>
							<td>{{ cliente.correo }}</td>
							<td>{{ cliente.telefono }}</td>
							<td>{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].club : '' }}</td>
							<td>
								{{ cliente.suscripciones.length > 0 ? cliente.suscripciones[0].estatus : '' }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-xl">Nuevos clubs ({{ newClubs ? newClubs.length : '' }})</p>

			<div
				v-if="newClubs.length > 0"
				class="flex w-full pt-4 overflow-x-scroll"
			>
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/2">Nombre</th>
							<th class="w-1/2">Correo</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cliente in newClubs"
							v-bind:key="cliente.id"
							class="cursor-pointer hover"
							@click="goToCliente(cliente.id)"
						>
							<td>{{ cliente.nombre }}</td>
							<td>{{ cliente.correo }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-xl">Clubs ({{ filteredClubs.length }})</p>

			<div class="pt-4 w-full flex flex-row space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Cargo</p>
					<select
						class="select select-sm select-bordered w-full"
						v-model="cargo"
					>
						<option
							selected
							value="todo"
						>
							Todos
						</option>
						<option value="mensual">Mensual</option>
						<option value="anual">Anual</option>
					</select>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Zona</p>
					<select
						v-model="zona"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							value="todo"
						>
							Todos
						</option>
						<option value="local">Local</option>
						<option value="foraneo">Foráneo</option>
					</select>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Club</p>
					<select
						v-model="club"
						class="select select-sm select-bordered w-full"
					>
						<option value="todo">Todos</option>
						<option value="uno_blanco">Uno (Blanco)</option>
						<option value="uno_tinto">Uno (Tinto)</option>
						<option value="explorer">Explorer</option>
						<option value="fearless">Fearless</option>
						<option value="bold">Bold</option>
						<option value="bright">Bright</option>
						<option value="wild">Wild</option>
					</select>
				</div>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/8">Nombre</th>
							<th class="w-1/8">Apellido</th>
							<th class="w-1/8">Correo</th>
							<th class="w-1/8">Lada</th>
							<th class="w-2/8">Teléfono</th>
							<th class="w-1/8">Cargo</th>
							<th class="w-1/8">Club</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="club in filteredClubs"
							v-bind:key="club.id"
							class="cursor-pointer hover"
							@click="goToCliente(club.cliente.id)"
						>
							<td>{{ club.cliente.nombre }}</td>
							<td>{{ club.cliente.apellido }}</td>
							<td>{{ club.cliente.correo }}</td>
							<td>{{ club.cliente.country_code }}</td>
							<td>{{ club.cliente.telefono }}</td>
							<td>{{ club.cargo }}</td>
							<td>{{ club.club }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
	name: 'ClientesList',
	setup() {
		const zona = ref('todo')
		const cargo = ref('todo')
		const club = ref('todo')

		const clubs = ref([])
		const newClubs = ref([])
		const clientes = ref([])
		const searchClientes = ref([])
		const searchText = ref('')

		const router = useRouter()

		let timeout

		let ac

		const filteredClubs = computed(() => {
			return clubs.value.filter((c) => {
				let z, ca, cl

				if (zona.value == 'todo') {
					z = true
				} else {
					z = c.zona == zona.value
				}

				if (cargo.value == 'todo') {
					ca = true
				} else {
					ca = c.cargo == cargo.value
				}

				if (club.value == 'todo') {
					cl = true
				} else {
					cl = c.club == club.value
				}

				console.log(z, ca, cl)
				return z && ca && cl
			})
		})

		async function searchCliente(text) {
			ac = new AbortController()

			const terms = text.split(' ')
			let query = supabase.from('clientes').select('*, suscripciones(*)')

			terms.forEach((term, index) => {
				const ilikeCondition = `nombre.ilike.%${term}%,apellido.ilike.%${term}%,correo.ilike.%${term}%,telefono.ilike.%${term}%`

				if (index === 0) {
					query = query.or(ilikeCondition)
				} else {
					query = query.or(ilikeCondition)
				}
			})

			const { data, error } = await query

			console.log(data, error)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function executeSearch(text) {
			searchCliente(text).then((data) => {
				searchClientes.value = data
			})
		}

		watch(searchText, async (newSearchText, oldSearchText) => {
			if (newSearchText == '') {
				searchClientes.value = clientes.value
				if (ac) {
					ac.abort()
				}
				return
			}

			console.log(newSearchText, oldSearchText)

			if (ac) {
				ac.abort()
			}

			clearTimeout(timeout)

			timeout = setTimeout(() => {
				executeSearch(newSearchText)
			}, 1000)
		})

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		async function getNewClubs() {
			const productIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

			const { data, error } = await supabase
				.from('pedido_detalles')
				.select('*, pedidos!inner(clientes(correo, nombre, apellido, id, suscripciones(*)))')
				.in('producto', productIds)
				.eq('pedidos.cancelado', false)
				.gte('created_at', '2024-10-02T00:00:00+00:00')
			// .eq('array_length(pedidos.clientes.suscripciones, 1)', 0);
			// .is('pedidos.clientes.suscripciones', null)

			console.log('getNewClubs2', error, data)
			if (error) {
				console.error('Error fetching clients without subscriptions:', error)
			} else {
				console.log('Clients without subscriptions:', data)
			}

			if (error) {
				console.error(error)
			} else {
				return data
					.filter((d) => d.pedidos.clientes.suscripciones.length == 0)
					.map((p) => p.pedidos.clientes)
			}
		}

		// async function getNewClubs() {
		// 	const productIds = [54727, 54728, 54729, 54730, 54731, 54732];

		// 	const { data, error } = await supabase
		// 	.from('clientes')
		// 	.select('*, suscripciones(cliente),  pedidos!inner(pedido_detalles!inner(producto))')
		// 	.in('pedidos.pedido_detalles.producto', productIds)
		// 	.eq('pedidos.cancelado', false);

		// 	if (error) {
		// 		console.error('Error fetching clients without subscriptions:', error);
		// 	} else {
		// 		console.log('Clients without subscriptions:', data);
		// 	}

		// 	if (error) {
		// 		console.error(error);
		// 	} else {
		// 		return data.filter( d => d.suscripciones.length == 0)
		// 	}
		// }

		async function getClubs() {
			const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(id, nombre, apellido, telefono, country_code, correo)')
				.eq('estatus', 'activo')

			let sorted = data.sort((a, b) => {
				const nameA = a.cliente.nombre
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
				const nameB = b.cliente.nombre
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')

				if (nameA < nameB) {
					return -1
				}
				if (nameA > nameB) {
					return 1
				}
				return 0
			})

			if (error) {
				console.error(error)
			} else {
				return sorted
			}
		}

		onMounted(async () => {
			getNewClubs().then((data) => {
				newClubs.value = data
			})

			getClubs().then((data) => {
				clubs.value = data
			})

			// getNewClubs2()
			// getClientes().then( data => {
			// 	clientes.value = data
			// 	searchClientes.value = data
			// })
		})

		return {
			zona,
			cargo,
			club,
			getNewClubs,
			clientes,
			searchCliente,
			searchClientes,
			searchText,
			goToCliente,
			newClubs,
			clubs,
			filteredClubs
		}
	}
}
</script>
