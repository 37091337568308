<template>
	<div class="p-4">
		<p class="text-2xl">Pedidos</p>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex space-x-2">
				<div class="w-full">
					<p class="text-sm pb-2">Ubicación</p>
					<select
						v-model="ubicacion"
						@change="filterPedidos()"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							value="todos"
						>
							Todos
						</option>
						<option
							v-for="ubicacion in ubicaciones"
							v-bind:key="ubicacion.id"
							:value="ubicacion"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Inicio</p>
					<input
						class="input input-bordered input-sm w-full"
						@change="refreshPedidos()"
						v-model="startDate"
						type="date"
					/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Fin</p>
					<input
						class="input input-bordered input-sm w-full"
						@change="refreshPedidos()"
						v-model="endDate"
						type="date"
					/>
				</div>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-32 min-w-32">Fecha</th>
							<th class="w-32 min-w-32">Ubicación</th>
							<th class="">Correo</th>
							<th class="w-16">Total</th>
							<th></th>
						</tr>
					</thead>
					<tbody
						v-for="pedido in pedidos"
						v-bind:key="pedido.id"
					>
						<tr class="hover cursor-pointer">
							<td @click="goToPedido(pedido.id)">
								{{ pedido.created_at.split('T')[0] }}
							</td>
							<td @click="goToPedido(pedido.id)">{{ pedido.ubicacion.nombre }}</td>
							<td @click="goToPedido(pedido.id)">
								{{ pedido.cliente ? pedido.cliente.correo : '' }}
							</td>
							<td @click="goToPedido(pedido.id)">${{ pedido.total }}</td>
							<td>
								<div class="flex justify-end">
									<svg
										v-if="!showItems[pedido.id]"
										@click="showItems[pedido.id] = true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="size-4"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="m4.5 15.75 7.5-7.5 7.5 7.5"
										/>
									</svg>

									<svg
										v-if="showItems[pedido.id]"
										@click="showItems[pedido.id] = false"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="size-4"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="m19.5 8.25-7.5 7.5-7.5-7.5"
										/>
									</svg>
								</div>
							</td>
						</tr>

						<tr v-if="showItems[pedido.id]">
							<td
								colspan="5"
								class="p-0"
							>
								<div class="pb-2 overflow-hidden">
									<div class="rounded-lg overflow-hidden bg-base-300">
										<table class="table table-xs">
											<thead class="sticky top-0">
												<tr>
													<th class="w-1/3">Producto</th>
													<th class="w-1/3">Cantidada</th>
													<th class="w-1/3">Total</th>
												</tr>
											</thead>
											<tbody
												v-for="pedido_detalle in pedido.pedido_detalles"
												v-bind:key="pedido_detalle.id"
											>
												<tr>
													<td>{{ pedido_detalle.producto?.nombre || pedido_detalle.reference }}</td>
													<td>{{ pedido_detalle.cantidad }}</td>

													<td>${{ pedido_detalle.total }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import utils from '../utils'

export default {
	name: 'AdminPedidos',
	setup() {
		const router = useRouter()
		const pedidos = ref([])

		const ubicaciones = ref([])
		const ubicacion = ref('todos')

		const startDate = ref('')
		const endDate = ref('')

		const showItems = ref({})

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq('activo', true)
				.order('order', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function filterPedidos() {
			pedidos.value = (await getPedidos()).filter((s) => {
				if (ubicacion.value == 'todos') {
					return true
				}

				return s.ubicacion.id == ubicacion.value.id
			})
		}

		async function getPedidos() {
			const { data } = await supabase
				.from('pedidos')
				.select(
					'*, cliente(nombre, apellido, correo), ubicacion(id, nombre), pedido_detalles(cantidad, producto(nombre), total, reference)'
				)
				.order('created_at', { ascending: false })
				.filter('created_at', 'gte', startDate.value)
				.filter('created_at', 'lte', addOneDay(endDate.value))

			data.map((p) => {
				let date = new Date(p.created_at)
				date.setHours(date.getHours() - 6)
				p.created_at = date.toISOString()
				return p
			})

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		function refreshPedidos() {
			getPedidos().then((data) => {
				pedidos.value = data
			})
		}

		onMounted(async () => {
			// const today = new Date();
			// endDate.value = today.toISOString().split('T')[0]
			// today.setMonth(today.getMonth() - 1);
			// startDate.value = today.toISOString().split('T')[0]

			startDate.value = getDateDaysAgo(7)
			endDate.value = getDateDaysAgo(0)

			getPedidos().then((data) => {
				pedidos.value = data
			})
			getUbicaciones().then((data) => {
				ubicaciones.value = data
			})
		})

		function addOneDay(dateString) {
			return utils.addOneDay(dateString)
		}

		function goToPedido(pedidoId) {
			if (event.getModifierState('Meta')) {
				window.open(`/pedido/${pedidoId}`, '_blank').focus()
			} else {
				router.push(`/pedido/${pedidoId}`)
			}
		}

		function getDateDaysAgo(daysAgo) {
			return new Date(new Date().setDate(new Date().getDate() - daysAgo))
				.toISOString()
				.split('T')[0]
		}

		return {
			pedidos,
			refreshPedidos,
			filterPedidos,
			ubicaciones,
			ubicacion,
			startDate,
			endDate,
			getPedidos,
			showItems,
			goToPedido
		}
	}
}
</script>
