<template>
	<dialog
		id="my_modal_3"
		class="modal"
	>
		<div class="modal-box bg-base-300">
			<form method="dialog">
				<button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
			</form>
			<div v-if="customer">
				<h3 class="text-lg font-bold">{{ customer.customers.email }}</h3>

				<div
					v-for="cus in customer.customers.customers"
					v-bind:key="cus.customerId"
					class="mt-4"
				>
					<div class="flex flex-row justify-between mt-4">
						<div class="text-sm">{{ cus.customerId }}</div>
						<div class="text-sm flex flex-row space-x-2">
							<!-- <div
								class="cursor-pointer"
								@click="getCardLink(cus.customerId)"
							>
								Card Link
							</div> -->
							<a
								target="_blank"
								class="underline"
								:href="`https://dashboard.stripe.com/customers/${cus.customerId}`"
								>Abrir Stripe</a
							>
						</div>
					</div>

					<select
						v-if="cus.paymentMethods.data.lengthy > 0"
						v-model="cus.paymentMethodId"
						class="select select-sm select-bordered w-full mt-2"
					>
						<option
							selected
							disabled
						>
							Default payment method
						</option>

						<option
							v-for="paymentMethod in cus.paymentMethods.data"
							v-bind:key="paymentMethod.id"
							:value="paymentMethod.id"
						>
							{{ paymentMethod.card.brand }} {{ paymentMethod.card.last4 }}
						</option>
					</select>

					<div v-if="cus.subscriptions.length > 0">
						<div
							v-for="sub in cus.subscriptions"
							v-bind:key="sub.subscriptionId"
							class="mt-2"
						>
							<div class="flex flex-row justify-between mt-4">
								<div class="text-sm">{{ sub.subscriptionId }}</div>
								<div class="text-sm">{{ sub.status }}</div>
							</div>

							<div v-if="sub.invoices.length > 0">
								<div
									v-for="inv in sub.invoices"
									v-bind:key="inv.id"
									class="mt-2"
								>
									<div class="bg-base-100 rounded-lg p-2 mt-2">
										<!-- {{ inv.created }} -->
										<div class="flex flex-row justify-between">
											<div class="text-sm">{{ stripeDate(inv.created) }}</div>
											<div class="text-sm">${{ inv.total / 100 }} {{ inv.status }}</div>
										</div>
										<div
											v-for="line in inv.lines.data"
											v-bind:key="line.url"
										>
											<!-- <div>${{ inv.total / 100 }}</div> -->
											<div class="text-sm">{{ line.description }}</div>
										</div>

										<button
											v-if="inv.status == 'open'"
											:disabled="buttons[inv.id]"
											@click="retryInvoice(inv.id, cus.customerId, cus.paymentMethodId)"
											class="btn btn-primary btn-sm no-animation w-full mt-2"
										>
											Cobrar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form
			method="dialog"
			class="modal-backdrop"
		>
			<button>close</button>
		</form>
	</dialog>

	<div class="p-4">
		<p class="text-2xl">Cargos</p>

		<div class="grid grid-cols-2 md:grid-cols-4 gap-4 overflow-x-scroll mt-4">
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Cargos actuales</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ currentPedidos.length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Faltantes</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ faltantes.length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Nuevos</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ currentPedidos.filter((p) => p.new).length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Pausados</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ pausados.length }}
				</div>
			</div>
		</div>

		<div class="grid grid-cols-2 md:grid-cols-4 gap-4 overflow-x-scroll pt-4">
			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Cargos pasados</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ previousPedidos.length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Cargos estimados</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ faltantes.length + currentPedidos.length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Cancelados</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ cancelados.length }}
				</div>
			</div>

			<div class="w-full rounded-lg p-4 bg-base-100">
				<div class="stat-title">Fallidos</div>
				<div class="stat-value text-xl md:text-4xl">
					{{
						previousPedidos.filter((pedido) => pedido.faltante_tipo == 'fallido' && !pedido.exists)
							.length
					}}
				</div>
			</div>
		</div>
		<div class="flex flex-row space-x-4">
			<div
				v-if="previousPedidos.length != 0"
				class="w-full mt-4 rounded-lg p-4 bg-base-100"
			>
				<div class="stat-title">Crecimiento</div>

				<div class="stat-value text-xl md:text-4xl">
					{{ faltantes.length + currentPedidos.length - previousPedidos.length }} ({{
						Math.round(
							((faltantes.length + currentPedidos.length - previousPedidos.length) /
								previousPedidos.length) *
								10000
						) / 100
					}}%)
				</div>
			</div>
			<div
				v-if="previousPedidos.length != 0"
				class="w-full mt-4 rounded-lg p-4 bg-base-100"
			>
				<div class="stat-title">Churn</div>
				<div class="stat-value text-xl md:text-4xl">
					{{ [...new Set(futureFaltantes.concat(futureFallidos))].length }} ({{
						Math.round(
							([...new Set(futureFaltantes.concat(futureFallidos))].length /
								previousPedidos.length) *
								10000
						) / 100
					}}%)
				</div>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex flex-row space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Periodo</p>
					<select
						v-model="period"
						@change="getPedidosForPeriod(period)"
						class="select select-sm select-bordered w-full"
					>
						<option
							v-for="period in periods"
							v-bind:key="`${period.month}${period.year}`"
							:value="period"
						>
							{{ period.month }}/{{ period.year }}
						</option>
					</select>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Tipo</p>
					<select
						v-model="filter"
						class="select select-sm select-bordered w-full"
					>
						<option value="">Todos</option>
						<option value="faltante">Faltantes</option>
						<option value="cancelado">Cancelado</option>
						<option value="pausado">Pausado</option>
						<option value="fallido">Fallido</option>
						<option value="otro">Otro</option>
						<option value="churn">Churn</option>
					</select>
				</div>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-10">Día</th>
							<th class="w-1/4">Correo</th>
							<th class="w-10">Club</th>
							<th class="w-10">Faltante</th>
							<th class="">Tipo</th>
							<th class="">Notas</th>
							<th class="w-28">Accion</th>
						</tr>
					</thead>
					<tbody
						v-for="pedido in previousPedidosFiltrados"
						v-bind:key="pedido.id"
					>
						<tr>
							<td>
								<router-link :to="`/pedido/${pedido.id}`">{{
									pedido.created_at.split('T')[0].split('-')[2]
								}}</router-link>
							</td>
							<td>
								<div class="flex space-x-2 items-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="size-4 cursor-pointer"
										@click="copyToClipboard(pedido.cliente.correo)"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
										/>
									</svg>

									<router-link :to="`/cliente/${pedido.cliente.id}`">{{
										pedido.cliente ? pedido.cliente.correo : ''
									}}</router-link>
								</div>
							</td>
							<td>{{ pedido.cliente.suscripciones[0].estatus }}</td>
							<td
								class="cursor-pointer"
								@click="getCustomer(pedido.cliente.correo)"
							>
								{{ pedido.exists == true ? 'No' : 'Si' }}
							</td>
							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.faltante_tipo }}</p>
								<select
									v-if="edit[pedido.id]"
									v-model="pedido.faltante_tipo"
									className="select select-sm select-block select-bordered w-full"
								>
									<option value="cancelado">Cancelado</option>
									<option value="pausado">Pausado</option>
									<option value="fallido">Fallido</option>
									<option value="otro">Otro</option>
									<option value="">Recuperado</option>
								</select>
							</td>
							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.faltante_notas }}</p>
								<input
									v-if="edit[pedido.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="pedido.faltante_notas"
								/>
							</td>
							<td>
								<div class="flex flex-row space-x-2">
									<button
										v-if="!edit[pedido.id]"
										@click="edit[pedido.id] = true"
										class="btn btn-primary btn-sm no-animation"
										style="width: 4.5rem"
									>
										Editar
									</button>
									<button
										v-if="edit[pedido.id]"
										@click="updatePedido(pedido)"
										class="btn btn-sm btn-square no-animation btn-primary"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="m4.5 12.75 6 6 9-13.5"
											/>
										</svg>
									</button>
									<button
										v-if="edit[pedido.id]"
										class="btn btn-sm btn-square no-animation btn-neutral"
										@click="cancelEdit(pedido)"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6 w-8"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M6 18 18 6M6 6l12 12"
											/>
										</svg>
									</button>
									<button
										v-if="pedido.faltante_tipo == 'fallido'"
										@click="mandarMensaje(pedido.cliente)"
										class="btn btn-sm btn-square no-animation btn-primary"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
											/>
										</svg>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { supabase } from '../supabase'
import utils from '../utils'
import { onMounted, ref, computed } from 'vue'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'
import axios from 'axios'

export default {
	name: 'AdminPedidos',
	setup() {
		const customer = ref(null)
		const periods = ref([])
		const period = ref({})
		const previousPedidos = ref([])
		const currentPedidos = ref([])
		const buttons = ref({})

		let edit = ref({})

		const filter = ref('')

		const loading = useLoading({
			loader: 'spinner'
		})

		async function getPedidos(start, end) {
			const productIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

			const { data } = await supabase
				.from('pedido_detalles')
				.select(
					'*, producto!inner(id, nombre), pedido!inner(*, cliente(nombre, id, correo, country_code, telefono, suscripciones(*)))'
				)

				.in('producto.id', productIds)

				.gte('created_at', start.toISOString())
				.lte('created_at', end.toISOString())
				.eq('pedido.cancelado', false)
				.order('created_at', { ascending: true })

			const c = data.map(({ pedido }) => {
				const adjustedDate = new Date(pedido.created_at)
				adjustedDate.setHours(adjustedDate.getHours() - 6)

				return {
					id: pedido.id,
					faltante_tipo: pedido.faltante_tipo,
					faltante_notas: pedido.faltante_notas,
					shopify_id: pedido.shopify_id,
					created_at: adjustedDate.toISOString(),
					cliente: pedido.cliente
				}
			})

			if (c) {
				return c
			} else {
				alert('No data returned')
			}
		}

		const previousPedidosFiltrados = computed(() => {
			return previousPedidos.value.filter((p) => {
				// let currentDay = (new Date()).getDate()
				// let pedidoDate = new Date(p.created_at)
				// pedidoDate.setHours(pedidoDate.getHours() + 6)
				// let pedidoDay = pedidoDate.getDate()

				switch (filter.value) {
					case 'churn':
						return !p.exists
					case 'cancelado':
						return (
							p.faltante_tipo == 'cancelado' || p.cliente.suscripciones[0].estatus == 'cancelado'
						)
					case 'pausado':
						return p.faltante_tipo == 'pausado' || p.cliente.suscripciones[0].estatus == 'pausado'
					case 'fallido':
						return p.faltante_tipo == 'fallido'
					case 'otro':
						return p.faltante_tipo == 'otro'
					case 'faltante':
						return !p.exists && p.cliente.suscripciones[0].estatus == 'activo' && !p.faltante_tipo //pedidoDay <= currentDay && p.exists == false //&& p.cliente.suscripciones[0].estatus == "activo"
					default:
						return true
				}
			})
		})

		//There are charges that are included in the churn that were charged in the current month, but were cancelled or paused after the fact. That is why the math doesn't always make sense.

		const faltantes = computed(() => {
			return previousPedidos.value
				.filter((p) => {
					return !p.exists && p.cliente.suscripciones[0].estatus == 'activo' && !p.faltante_tipo
				})
				.map((p) => p.cliente.id)
		})

		const cancelados = computed(() => {
			return previousPedidos.value
				.filter(
					(pedido) =>
						pedido.faltante_tipo == 'cancelado' ||
						pedido.cliente.suscripciones[0].estatus == 'cancelado'
				)
				.filter((p) => !p.exists)
				.map((p) => p.cliente.id)
		})

		const pausados = computed(() => {
			return previousPedidos.value
				.filter(
					(pedido) =>
						pedido.faltante_tipo == 'pausado' ||
						pedido.cliente.suscripciones[0].estatus == 'pausado'
				)
				.filter((p) => !p.exists)
				.map((p) => p.cliente.id)
		})

		const futureFallidos = computed(() => {
			return previousPedidos.value
				.filter((pedido) => ['cancelado', 'pausado', 'fallido'].includes(pedido.faltante_tipo))
				.filter((p) => !p.exists)
				.map((p) => p.id)
		})

		const futureFaltantes = computed(() => {
			return previousPedidos.value
				.filter((pedido) =>
					['cancelado', 'pausado'].includes(pedido.cliente.suscripciones[0].estatus)
				)
				.filter((p) => !p.exists)
				.map((p) => p.id)
		})

		onMounted(async () => {
			const d = new Date()

			periods.value = getLastMonths(d.getMonth() + 1, d.getFullYear())
			period.value = periods.value[0]

			await getPedidosForPeriod(period.value)
		})

		async function getPedidosForPeriod(period) {
			let loader = loading.show()
			// console.log('period', period)

			const { previousMonth, currentMonth } = getMonthDates(period.month, period.year)

			// console.log(previousMonth, currentMonth)

			const [previous, current] = await Promise.all([
				getPedidos(previousMonth.startDate, previousMonth.endDate),
				getPedidos(currentMonth.startDate, currentMonth.endDate)
			])

			// console.log(previous, current)

			previousPedidos.value = previous.map((p) => {
				p.copy = structuredClone(p)
				return p
			})

			currentPedidos.value = current.map((p) => {
				p.copy = structuredClone(p)
				return p
			})

			checkIfObjectsExist(previousPedidos.value, currentPedidos.value, 'cliente')

			loader.hide()
		}

		function checkIfObjectsExist(array1, array2, key) {
			array1.forEach((obj1) => {
				obj1.exists = array2.some((obj2) => obj2[key].id === obj1[key].id)
				obj1.copy.exists = array2.some((obj2) => obj2[key].id === obj1[key].id)
			})

			array2.forEach((obj1) => {
				obj1.new = !array1.some((obj2) => obj2[key].id === obj1[key].id)
				obj1.copy.new = !array1.some((obj2) => obj2[key].id === obj1[key].id)
			})
			return array1
		}

		function getMonthDates(month, year) {
			// console.log('getMonthDates', month, year)

			let currentMonthStart = new Date(year, month - 1, 1)
			// currentMonthStart.setHours(currentMonthStart.getHours() + 6)

			// console.log('currentMonthStart', currentMonthStart.toISOString())

			let currentMonthEnd = new Date(year, month, 0)
			currentMonthEnd.setHours(currentMonthEnd.getHours() + 24)

			console.log('currentMonthEnd', currentMonthEnd.toISOString())

			const previousMonth = month === 1 ? 12 : month - 1
			const previousYear = month === 1 ? year - 1 : year

			let previousMonthStart = new Date(previousYear, previousMonth - 1, 1)
			// previousMonthStart.setHours(previousMonthStart.getHours() + 6)

			console.log('previousMonthStart', previousMonthStart.toISOString())

			let previousMonthEnd = new Date(previousYear, previousMonth, 0)
			previousMonthEnd.setHours(previousMonthEnd.getHours() + 24)

			console.log('previousMonthEnd', previousMonthEnd.toISOString())

			return {
				currentMonth: {
					startDate: currentMonthStart,
					endDate: currentMonthEnd
				},
				previousMonth: {
					startDate: previousMonthStart,
					endDate: previousMonthEnd
				}
			}
		}

		function getLastMonths(month, year) {
			const months = []

			for (let i = 0; i < getMonthsSinceStart(); i++) {
				months.push({ month, year })

				month--
				if (month === 0) {
					month = 12
					year--
				}
			}

			return months
		}

		function getMonthsSinceStart() {
			const now = new Date()
			const past = new Date('2024-11-01')

			const yearsDifference = now.getFullYear() - past.getFullYear()
			const monthsDifference = now.getMonth() - past.getMonth()

			return yearsDifference * 12 + monthsDifference
		}

		async function updatePedido(pedido) {
			if (!confirm('¿Quieres guardar tus cambios?')) {
				return cancelEdit(pedido)
			}

			let data = {
				faltante_tipo: pedido.faltante_tipo == '' ? null : pedido.faltante_tipo,
				faltante_notas: pedido.faltante_notas
			}

			const { error } = await supabase.from('pedidos').update(data).eq('id', pedido.id)

			if (error) {
				console.log(error)
				return alert(error.details)
			}

			replaceObjectInArray(previousPedidos.value, pedido)

			useToast().success('Pedido actualizada', { position: 'top-right' })

			edit.value[pedido.id] = false
		}

		function cancelEdit(pedido) {
			edit.value[pedido.id] = false

			console.log(pedido)

			replaceObjectInArray(previousPedidos.value, pedido.copy)
		}

		function replaceObjectInArray(array, newObject) {
			const index = array.findIndex((obj) => obj.id === newObject.id)

			// console.log()
			if (index !== -1) {
				array[index] = newObject
				array[index].copy = structuredClone(newObject)
			}

			return array
		}

		function copyToClipboard(text) {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					useToast().success('Correo copiado', { position: 'top-right' })
				})
				.catch((err) => {
					console.error('Error in copying text: ', err)
				})
		}

		async function mandarMensaje(cliente) {
			let mensaje = {
				template: 'cargo_fallido',
				nombre: 'Cargo fallido'
			}

			let params = [
				{
					name: 'nombre',
					value: cliente.nombre
				}
			]

			if (!confirm('¿Quieres mandar el mensaje?')) {
				return
			}

			let loader = loading.show()

			let statusMandar = await utils.mandarMensaje(cliente, mensaje, params)

			loader.hide()

			if (!statusMandar) {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
				return
			}

			let error = await utils.addMessage(mensaje, cliente)

			if (error) {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			} else {
				useToast().success('Mensaje mandado', { position: 'top-right' })
			}
		}

		async function getCustomer(email) {
			let loader = loading.show()
			let { data } = await axios.get(`/api/stripe/customer?email=${email}`)
			customer.value = data
			console.log(customer.value)

			loader.hide()

			document.getElementById('my_modal_3').showModal()
		}

		async function getCardLink(customerId) {
			let { data } = await axios.get(`/api/stripe/card_link?customerId=${customerId}`)

			copyToClipboard(data.url)
		}

		async function retryInvoice(invoiceId, customerId, paymentMethodId) {
			buttons.value[invoiceId] = true

			let { data } = await axios.get(`/api/stripe/invoice`, {
				params: {
					invoiceId,
					customerId,
					...(paymentMethodId && paymentMethodId)
				}
			})

			console.log(data)

			if (data.invoice) {
				useToast().success('¡Invoice cobrado!', { position: 'top-right' })
			} else if (data.error) {
				useToast().error(data.error, { position: 'top-right' })
			} else {
				useToast().error('No se pudo cobrar el invoice :(', { position: 'top-right' })
			}

			buttons.value[invoiceId] = false
		}

		function stripeDate(timestamp) {
			const date = new Date(timestamp * 1000)

			// Extract day, month, and year
			const day = String(date.getDate()).padStart(2, '0')
			const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
			const year = date.getFullYear()

			// Format as dd mm yyyy
			return `${day}/${month}/${year}`
		}

		return {
			buttons,
			getCardLink,
			customer,
			retryInvoice,
			stripeDate,
			getCustomer,
			getPedidosForPeriod,
			previousPedidos,
			previousPedidosFiltrados,
			currentPedidos,
			periods,
			period,
			edit,
			cancelEdit,
			updatePedido,
			filter,
			copyToClipboard,
			mandarMensaje,
			faltantes,
			cancelados,
			pausados,
			futureFallidos,
			futureFaltantes
		}
	}
}
</script>
