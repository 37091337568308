<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Crear cliente</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="space-y-4">
				<div class="flex flex-row w-full space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Nombre</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Nombre"
							type="text"
							v-model="cliente.nombre"
						/>
					</div>

					<div class="w-full">
						<p class="text-sm pb-2">Apellido</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Apellido"
							type="text"
							v-model="cliente.apellido"
						/>
					</div>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Correo</p>
					<input
						class="input input-bordered input-sm w-full"
						placeholder="Correo"
						type="text"
						v-model="cliente.correo"
					/>
				</div>
				<div class="flex flex-row w-full space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Pais</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Lada"
							type="text"
							v-model="cliente.country_code"
						/>
					</div>
					<div class="w-full">
						<p class="text-sm pb-2">Número tel.</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Número"
							type="text"
							v-model="cliente.telefono"
						/>
					</div>
				</div>
				<button
					@click="createCliente()"
					class="mt-4 btn btn-primary btn-sm no-animation w-full"
				>
					Crear
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'
import { useRouter } from 'vue-router'

export default {
	name: 'AdminCatas',
	setup() {
		const cliente = ref({})

		const router = useRouter()

		const loading = useLoading({
			loader: 'spinner'
		})

		async function createCliente() {
			if (
				!cliente.value.nombre ||
				!cliente.value.apellido ||
				!cliente.value.correo ||
				!cliente.value.country_code ||
				!cliente.value.telefono
			) {
				return alert('Favor de llenar toda la info')
			}

			let loader = loading.show()

			let shopifyCliente = await createInShopify(loader)

			if (!shopifyCliente) {
				return
			}

			console.log(shopifyCliente)

			cliente.value.shopify_id = shopifyCliente.customer.id
			cliente.value.shopify = shopifyCliente.customer
			cliente.value.telefono = cliente.value.telefono.replaceAll(' ', '')

			const { data, error } = await supabase
				.from('clientes')
				.upsert(cliente.value)
				.select()
				.single()

			if (error) {
				console.log(error)
				useToast().error('No jaló', { position: 'top-right' })
			}

			loader.hide()
			useToast().success('Éxito', { position: 'top-right' })

			console.log(data)

			cliente.value = ref('')

			router.push(`/cliente/${data.id}`)
		}

		async function createInShopify(loader) {
			try {
				let { status, data } = await axios.post('/api/create_customer', cliente.value)

				if (status != 200) {
					useToast().error('No jaló', { position: 'top-right' })
					loader.hide()
					return
				}

				return data
			} catch (e) {
				alert(e)
				useToast().error('No jaló', { position: 'top-right' })
				loader.hide()
				return
			}

			// .then( data => {
			// 	return data
			// }).catch( e => {
			// 	alert(e)
			// useToast().error("No jaló", { position: "top-right" });
			// loader.hide()
			// return
			// })
		}

		onMounted(async () => {})

		return {
			cliente,
			createCliente
		}
	}
}
</script>

<!-- {
	"customer": {
		"id": 6873637453883,
		"email": "no.ahtovares@gmail.com",
		"created_at": "2024-10-05T14:08:37-06:00",
		"updated_at": "2024-10-05T14:08:38-06:00",
		"first_name": "b",
		"last_name": "a",
		"orders_count": 0,
		"state": "disabled",
		"total_spent": "0.00",
		"last_order_id": null,
		"note": null,
		"verified_email": true,
		"multipass_identifier": null,
		"tax_exempt": false,
		"tags": "",
		"last_order_name": null,
		"currency": "MXN",
		"phone": "+525521311288",
		"addresses": [],
		"tax_exemptions": [],
		"email_marketing_consent": {
			"state": "not_subscribed",
			"opt_in_level": "single_opt_in",
			"consent_updated_at": null
		},
		"sms_marketing_consent": {
			"state": "not_subscribed",
			"opt_in_level": "single_opt_in",
			"consent_updated_at": null,
			"consent_collected_from": "OTHER"
		},
		"admin_graphql_api_id": "gid://shopify/Customer/6873637453883"
	}
} -->
