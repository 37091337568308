<template>
	<div class="p-4">
		<div class="block space-y-4 sm:flex sm:space-y-0 flex-row justify-between">
			<p class="text-2xl">Clubs</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div
				role="tablist"
				class="tabs tabs-boxed w-full"
			>
				<a
					@click="filterCondition = 'buzon'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'buzon' ? 'tab-active' : '']"
					>Buzón</a
				>
				<a
					@click="filterCondition = 'local'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'local' ? 'tab-active' : '']"
					>Local</a
				>
				<a
					@click="filterCondition = 'foraneo'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'foraneo' ? 'tab-active' : '']"
					>Foráneo</a
				>
				<a
					@click="filterCondition = 'pickup'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'pickup' ? 'tab-active' : '']"
					>Pickup</a
				>
				<a
					@click="filterCondition = 'todo'"
					role="tab"
					class="tab"
					:class="[filterCondition == 'todo' ? 'tab-active' : '']"
					>Todo</a
				>
			</div>

			<div
				v-if="pedidosFiltrados.length > 0"
				class="flex w-full pt-4 overflow-x-scroll"
			>
				<table class="table table-xs">
					<thead class="bg-base-100 border-collapse">
						<tr>
							<th class="w-24 min-w-24">Fecha</th>
							<th class="w-32">Shopify ID</th>
							<th class="min-w-32">Producto</th>
							<th class="">Cliente</th>
							<th class="w-32 min-w-32">Entgrega</th>
							<th class="">Notas</th>
							<th
								class=""
								style="width: 7.5rem"
							>
								Accion
							</th>
						</tr>
					</thead>
					<tbody
						v-for="pedido in pedidosFiltrados"
						v-bind:key="pedido.id"
					>
						<tr
							v-for="pedido_detalle in pedido.pedido_detalles"
							v-bind:key="pedido_detalle.id"
						>
							<td>{{ pedido.created_at.split('T')[0] }}</td>
							<td>{{ pedido.shopify_id }}</td>
							<td>{{ pedido_detalle.producto.nombre }}</td>
							<td
								v-if="pedido.cliente"
								class="cursor-pointer"
								@click="goToCliente(pedido.cliente.id)"
							>
								{{ pedido.cliente.nombre }} {{ pedido.cliente.apellido }}

								<div class="text-xs opacity-50">{{ pedido.cliente.notas }}</div>
							</td>
							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.delivery_type_copy }}</p>
								<select
									v-else
									v-model="pedido.delivery_type_copy"
									class="select select-sm select-bordered w-full"
								>
									<option
										selected
										disabled
										:value="null"
									>
										Escoger
									</option>
									<option value="local">Local</option>
									<option value="foraneo">Foráneo</option>
									<option value="pickup">Pickup</option>
									<option value="sin_entrega">Sin entrega</option>
								</select>
							</td>

							<td>
								<p v-if="!edit[pedido.id]">{{ pedido.notas }}</p>
								<input
									v-if="edit[pedido.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="pedido.notas"
								/>
							</td>
							<td>
								<div class="flex flex-row space-x-2">
									<div
										v-if="filterCondition == 'buzon'"
										class="w-full"
									>
										<!-- <button
											@click="mandarUpsell(pedido)"
											class="btn btn-sm btn-primary no-animation w-full"
										>
											Mandar upsell
										</button> -->

										<button
											@click="mandarUpsell(pedido)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Mandar upsell"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
												/>
											</svg>
										</button>
									</div>
									<div
										v-if="filterCondition == 'buzon'"
										class="w-full"
									>
										<button
											@click="marcarListo(pedido, true)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Marcar listo"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
												/>
											</svg>
										</button>
									</div>
									<div
										v-if="filterCondition == 'local'"
										class="w-full"
									>
										<button
											@click="mandarOnfleet(pedido, pedido_detalle)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Mandar onfleet"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
												/>
											</svg>
										</button>
									</div>
									<div
										v-if="filterCondition == 'foraneo'"
										class="w-full"
									>
										<button
											@click="marcarEnviado(pedido)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Mandar enviado"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
												/>
											</svg>
										</button>
									</div>
									<div
										v-if="filterCondition == 'pickup'"
										class="w-full"
									>
										<button
											@click="marcarEnviado(pedido)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Mandar enviado"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
												/>
											</svg>
										</button>
									</div>
									<div
										v-if="filterCondition == 'todo'"
										class="w-full"
									>
										<button
											@click="marcarListo(pedido, null)"
											class="btn btn-sm btn-square no-animation btn-primary tooltip flex items-center justify-center"
											data-tip="Regresar al buzón"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z"
												/>
											</svg>
										</button>
									</div>
									<div class="flex flex-row space-x-2">
										<button
											v-if="!edit[pedido.id]"
											@click="edit[pedido.id] = true"
											class="btn btn-primary btn-sm no-animation"
											style="width: 4.5rem"
										>
											Editar
										</button>
										<button
											v-if="edit[pedido.id]"
											@click="updatePedido(pedido)"
											class="btn btn-sm btn-square no-animation btn-primary"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="m4.5 12.75 6 6 9-13.5"
												/>
											</svg>
										</button>
										<button
											v-if="edit[pedido.id]"
											class="btn btn-sm btn-square no-animation btn-neutral"
											@click="cancelEdit(pedido)"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												class="size-6 w-8"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M6 18 18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminSalidas',
	setup() {
		const router = useRouter()

		const pedidos = ref([])
		const pedidosCopy = ref([])

		const loading = useLoading({
			loader: 'spinner'
		})

		let filterCondition = ref('buzon')

		let edit = ref({})

		function getDateDaysAgo(daysAgo) {
			return new Date(new Date().setDate(new Date().getDate() - daysAgo))
				.toISOString()
				.split('T')[0]
		}

		async function getPedidos() {
			const productIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

			let loader = loading.show()

			const { data } = await supabase
				.from('pedido_detalles')
				.select(
					'*, producto!inner(id, nombre), pedido!inner(id, shopify_id, delivery_type, delivery_ready, delivered, enviado, notas, cliente(*, suscripciones(*)))'
				)

				.in('producto.id', productIds)
				.or('delivery_type.is.null,delivery_type.neq.sin_entrega', {
					referencedTable: 'pedido'
				})

				.order('created_at', { ascending: false })
				.gte('created_at', getDateDaysAgo(45))

			loader.hide()

			if (data) {
				return (
					data
						// .filter((d) => d.pedido.enviado != true) //Move to the query
						.map((d) => {
							let pedido = {
								id: d.pedido.id,
								shopify_id: d.pedido.shopify_id,
								created_at: d.created_at,
								notas: d.pedido.notas,
								delivery_ready: d.pedido.delivery_ready,
								delivery_type: d.pedido.delivery_type,
								enviado: d.pedido.enviado,
								cliente: d.pedido.cliente,
								pedido_detalles: [
									{
										producto: { nombre: d.producto.nombre }
									}
								]
							}

							if (!pedido.delivery_type && pedido.cliente.suscripciones[0]) {
								pedido.delivery_type = pedido.cliente.suscripciones[0].zona
								edit.value[pedido.id] = true
							}

							return pedido
						})
				)
			} else {
				alert('No data returned')
			}

			// const productIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]

			// const { data, error } = await supabase
			// 	.from('pedidos')
			// 	.select(
			// 		'*, cliente(id, nombre, notas, apellido, correo, country_code, telefono, direccion), pedido_detalles!inner(producto!inner(*)))'
			// 	)
			// 	.in('pedido_detalles.producto.id', productIds)
			// 	.or('delivery_type.is.null,delivery_type.neq.sin_entrega')
			// 	.order('created_at', { ascending: false })
			// 	.gte('created_at', '2024-10-02T00:00:00+00:00')
			// 	.eq('cancelado', false)

			// console.log(data, error)

			// if (data) {
			// 	return data
			// } else {
			// 	alert('No data returned')
			// }
		}

		function clubCount(filter) {
			return getPedidosForFilter(filter).length
		}

		function getPedidosForFilter(filter) {
			return pedidos.value.filter((p) => {
				switch (filter) {
					case 'buzon':
						return !p.delivery_ready
					case 'local':
						return p.delivery_ready == true && p.delivery_type == 'local' && !p.enviado
					case 'foraneo':
						return p.delivery_ready == true && p.delivery_type == 'foraneo' && !p.enviado
					case 'pickup':
						return p.delivery_ready == true && p.delivery_type == 'pickup' && !p.enviado
					default:
						return true
				}
			})
		}

		const pedidosFiltrados = computed(() => {
			return getPedidosForFilter(filterCondition.value)
		})

		function marcarListo(pedido, status) {
			if (!pedido.delivery_type) {
				return alert('Escoger un tipo de entrega')
			}

			if (edit.value[pedido.id]) {
				return alert('Primero, guarda tus cambios')
			}

			pedido.delivery_ready = status

			if (status == null) {
				pedido.enviado = null
			}

			updatePedido(pedido)
		}

		function marcarEnviado(pedido) {
			pedido.enviado = true
			updatePedido(pedido)
		}

		async function updatePedido(pedido) {
			if (!confirm('¿Quieres guardar tus cambios?')) {
				return cancelEdit(pedido)
			}

			console.log(pedido)

			let loader = loading.show()

			pedido.delivery_type = pedido.delivery_type_copy

			const { error } = await supabase
				.from('pedidos')
				.update({
					notas: pedido.notas,
					delivery_ready: pedido.delivery_ready,
					delivery_type: pedido.delivery_type,
					enviado: pedido.enviado
				})
				.eq('id', pedido.id)

			console.log(error)
			if (error) {
				return alert(error)
			}

			pedidosCopy.value = structuredClone(pedidos.value)

			useToast().success('Pedido actualizada', { position: 'top-right' })

			loader.hide()

			edit.value[pedido.id] = false
		}

		async function mandarOnfleet(pedido, pedido_detalle) {
			let loader = loading.show()
			axios
				.post('/api/onfleet', {
					cliente: pedido.cliente,
					producto: pedido_detalle.producto.nombre
				})
				.then(async (res) => {
					const { error } = await supabase
						.from('pedidos')
						.update({ enviado: true })
						.eq('id', pedido.id)

					if (error) {
						throw error
					} else {
						pedido.enviado = true
					}

					return res
				})
				.then((res) => {
					alert(
						`chidOS: ${JSON.stringify(
							res.data.task.destination.address
						)}\n\nOnfleet: ${JSON.stringify(res.data.data.destination.address)}`
					)
					loader.hide()
					useToast().success('Éxito', { position: 'top-right' })
				})
				.catch((e) => {
					console.log(e)

					try {
						alert(e.response.data.message.cause)
					} catch (e) {
						alert('Hubo un error creando el task en Onfleet')
					}

					loader.hide()
					useToast().error('No jaló', { position: 'top-right' })
				})
		}

		function cancelEdit(pedido) {
			edit.value[pedido.id] = false
			pedidos.value = structuredClone(pedidosCopy.value)
		}

		async function mandarUpsell(pedido) {
			if (!pedido.delivery_type) {
				return alert('Escoger un tipo de entrega')
			}

			if (!confirm('¿Quieres mandar el mensaje?')) {
				return cancelEdit(pedido)
			}

			let loader = loading.show()

			let template

			switch (pedido.delivery_type) {
				case 'local':
					template = 'upsell_local_v3'
					break
				case 'foraneo':
					template = 'upsell_foraneo_v3'
					break
				case 'pickup':
					template = 'upsell_pickup_v2'
					break
				default:
					loader.hide()
					return alert('Primero escoge una entrega')
			}

			let { status } = await axios.post('/api/blast', {
				template_name: template,
				broadcast_name: `upsell_${pedido.shopify_id}`,
				receivers: [
					{
						whatsappNumber: `${pedido.cliente.country_code.replace(/\D/g, '')}1${
							pedido.cliente.telefono
						}`,
						customParams: [
							{
								name: 'nombre',
								value: pedido.cliente.nombre
							},
							{
								name: 'apellido',
								value: pedido.cliente.apellido
							},
							{
								name: 'correo',
								value: pedido.cliente.correo
							}
						]
					}
				]
			})

			loader.hide()

			if (status == 200) {
				useToast().success('Mensaje mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}

			await addMessage({
				template: template,
				nombre: 'Upsell',
				cliente: pedido.cliente.id
			})
		}

		async function addMessage(mensaje) {
			const { error } = await supabase.from('mensajes').insert(mensaje)

			if (error) {
				console.log(error)
				return alert(error)
			}

			useToast().success('Cliente actualizada', { position: 'top-right' })
		}

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		onMounted(async () => {
			getPedidos().then((data) => {
				pedidos.value = data.map((p) => {
					p.delivery_type_copy = p.delivery_type
					return p
				})
				pedidosCopy.value = JSON.parse(JSON.stringify(data))
			})
		})

		return {
			pedidos,
			marcarListo,
			marcarEnviado,
			mandarUpsell,
			mandarOnfleet,
			pedidosFiltrados,
			edit,
			updatePedido,
			cancelEdit,
			filterCondition,
			clubCount,
			goToCliente
		}
	}
}
</script>
