<template>
	<div class="p-4">
		<p class="text-2xl">Actualizar Inventario</p>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="w-full grid grid-cols-2 gap-4">
				<div>
					<p class="pb-2 text-sm">Ubicación</p>
					<select
						v-model="ubicacion"
						@change="refreshProductos()"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Escoger una ubicación
						</option>
						<option
							v-for="ubicacion in ubicaciones"
							v-bind:key="ubicacion.id"
							:value="ubicacion.id"
						>
							{{ ubicacion.nombre }}
						</option>
					</select>
				</div>

				<div>
					<p class="pb-2 text-sm">Proveedor</p>
					<select
						v-model="proveedor"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							value=""
						>
							Escoger un proveedor
						</option>
						<option
							selected
							value="todos"
						>
							Todos
						</option>
						<option
							v-for="proveedor in proveedores"
							v-bind:key="proveedor.id"
							:value="proveedor"
						>
							{{ proveedor.nombre }}
						</option>
					</select>
				</div>
			</div>

			<div class="flex w-full">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th>Producto</th>
							<!-- <th>Entradas</th>
						<th>Salidas</th>
						<th>Ajustes</th>-->
							<th v-if="!retail">Total</th>
							<th>Actual</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="producto in filteredProductos"
							v-bind:key="producto.id"
						>
							<th>{{ producto.nombre }}</th>
							<td v-if="!retail">{{ getTotal(producto) }}</td>
							<td>
								<input
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="numeric"
									v-model="producto.actual"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="">
				<button
					@click="confirmarAjustes()"
					class="w-full btn btn-primary btn-sm no-animation"
				>
					Actualizar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import axios from 'axios'

export default {
	name: 'AdminActualizarInventario',
	setup() {
		const ubicacion = ref('')
		const proveedor = ref('todos')
		const proveedores = ref(null)
		const ubicaciones = ref(['Todos'])
		const productos = ref([])
		const retail = ref(false)
		const route = useRoute()
		const router = useRouter()
		const loading = useLoading({
			loader: 'spinner'
		})

		let loader

		function getTotal(producto) {
			return getEntradas(producto) - getSalidas(producto) + getAjustes(producto)
		}

		function getSalidas(producto) {
			return producto.salidas.length > 0
				? producto.salidas.map((s) => s.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getEntradas(producto) {
			return producto.entradas.length > 0
				? producto.entradas.map((e) => e.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		function getAjustes(producto) {
			return producto.ajustes.length > 0
				? producto.ajustes.map((a) => a.cantidad).reduce((prev, next) => prev + next)
				: 0
		}

		async function getUbicaciones() {
			const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq('activo', true)
				.order('order', { ascending: true })

			if (data) {
				if (retail.value) {
					return data.filter(
						(u) =>
							u.nombre != 'Bodega' && u.nombre != 'Oficina' && u.nombre != 'Tasting Room - Roma'
					)
				}

				return data
			} else {
				alert('No data returned')
			}
		}

		async function getProductos() {
			let query = supabase
				.from('productos')
				.select('id, nombre, salidas (*), entradas (*), ajustes (*), proveedor (*)')
				.eq('activo', true)
				.order('nombre', { ascending: true })

			if (ubicacion.value && ubicacion.value != 'todo') {
				query = query.eq('salidas.ubicacion', ubicacion.value)
				query = query.eq('entradas.ubicacion', ubicacion.value)
				query = query.eq('ajustes.ubicacion', ubicacion.value)
			}

			const { data } = await query

			if (data) {
				return data.sort((a, b) =>
					(a.proveedor ? a.proveedor.nombre : '') > (b.proveedor ? b.proveedor.nombre : '')
						? 1
						: (b.proveedor ? b.proveedor.nombre : '') > (a.proveedor ? a.proveedor.nombre : '')
						? -1
						: 0
				)
			} else {
				alert('No data returned')
			}
		}

		async function getProveedores() {
			const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function refreshProductos() {
			productos.value = await getProductos()
		}

		async function confirmarAjustes() {
			let inventario = productos.value.map((p) => {
				return {
					producto: p.id,
					producto_nombre: p.nombre,
					cantidad_actual: p.actual,
					cantidad_calculado: getTotal(productos.value.find((uP) => uP.id == p.id)),
					cantidad_nueva: p.actual - getTotal(productos.value.find((uP) => uP.id == p.id))
				}
			})

			let diferencias = inventario.filter((i) => {
				return i.cantidad_actual != undefined && i.cantidad_actual != i.cantidad_calculado
			})

			console.log(diferencias)

			if (diferencias.length > 0) {
				if (
					confirm(
						`Hay diferencias en:\n${diferencias
							.map((d) => {
								return d.producto_nombre
							})
							.join('\n')}\n\n¿Estás seguro que quieres actualizar el inventario?`
					)
				) {
					crearAjustes(inventario)
				}
			} else {
				crearAjustes(inventario)
			}
		}

		async function crearAjustes(inventario) {
			let { status } = await axios.post('/api/inventario', {
				ubicacion: ubicacion.value,
				inventario: inventario
			})

			console.log(status)

			let negatives = productos.value.filter((p) => {
				return p.actual < 0
			})

			if (negatives.length > 0) {
				return alert('No puedes tener menos de 0 de un producto')
			}

			loader = loading.show()

			let ajustes = productos.value
				.filter((p) => {
					return p.actual != null && getTotal(p) != p.actual
				})
				.map((p) => {
					return {
						producto: p.id,
						ubicacion: ubicacion.value,
						cantidad: p.actual - getTotal(productos.value.find((uP) => uP.id == p.id)),
						actual: p.actual,
						total: getTotal(productos.value.find((uP) => uP.id == p.id))
					}
				})

			await supabase.from('ajustes').insert(ajustes)

			if (retail.value == true) {
				return router.push('/gracias')
			}

			await refreshProductos()
			loader.hide()
			useToast().success('Inventario actualizado', { position: 'top-right' })

			// return router.push("/")
		}

		const filteredProductos = computed(() => {
			return productos.value.filter((p) =>
				proveedor.value && proveedor.value.id
					? p.proveedor && p.proveedor.id == proveedor.value.id
					: true
			)
		})

		onMounted(async () => {
			if (route.query.retail) {
				retail.value = true
			}

			ubicaciones.value = await getUbicaciones()
			proveedores.value = await getProveedores()
		})

		return {
			ubicacion,
			ubicaciones,
			productos,
			getEntradas,
			getAjustes,
			getSalidas,
			getTotal,
			confirmarAjustes,
			refreshProductos,
			retail,
			filteredProductos,
			proveedor,
			proveedores
		}
	}
}
</script>
