<template>
	<div class="p-4">
		<p class="text-2xl">Transfers</p>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th>Fecha</th>
						<th>Desde</th>
						<th>Hacia</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="transfer in transfers"
						v-bind:key="transfer.id"
						@click="goTo(transfer.id)"
						class="hover cursor-pointer"
					>
						<th>{{ transfer.created_at.split('T')[0] }}</th>
						<th>{{ transfer.from.nombre }}</th>
						<th>{{ transfer.to.nombre }}</th>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'

export default {
	name: 'AdminTransfers',
	setup() {
		const transfers = ref([])
		const router = useRouter()

		async function getTransfers() {
			const { data } = await supabase
				.from('transfers')
				.select('id, created_at, from (nombre), to (nombre)')
				.order('created_at', { ascending: false })

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		function goTo(id) {
			if (event.getModifierState('Meta')) {
				window.open(`/transfer/${id}`, '_blank').focus()
			} else {
				router.push(`/transfer/${id}`)
			}
		}

		onMounted(async () => {
			transfers.value = await getTransfers()
		})

		return {
			transfers,
			goTo
		}
	}
}
</script>
