<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Cancelaciones</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex w-full overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="">Nombre</th>
							<th class="">Correo</th>
							<th class="">Fecha cancelación</th>
							<th class="">Razón cancelación</th>
							<th class="">Catas</th>
							<th class="">Meses</th>
							<th class="">LTV</th>
							<th class="">Cargo</th>
							<th class="">Club</th>
							<th class="w-8"></th>
						</tr>
					</thead>

					<tbody
						v-for="club in clubs"
						v-bind:key="club.id"
					>
						<tr class="hover">
							<td
								class="cursor-pointer"
								@click="goToCliente(club.cliente.id)"
							>
								{{ club.cliente.nombre }} {{ club.cliente.apellido }}
							</td>
							<td>{{ club.cliente.correo }}</td>
							<td>{{ club.cancelacion_fecha }}</td>
							<td>{{ club.cancelacion_razon }}</td>
							<td>{{ club.cliente.reservas.length > 0 ? 'Si' : 'No' }}</td>
							<td>
								{{
									club.cliente.pedidos
										.map((p) => p.pedido_detalles)
										.flat()
										.filter((p) => (p.producto ? isProductClub(p.producto) : false)).length
								}}
							</td>
							<td class="text-right">
								${{
									Number(
										club.cliente.pedidos.map((p) => p.total).reduce((a, b) => a + b, 0)
									).toFixed(0)
								}}
							</td>
							<td>{{ club.cargo }}</td>
							<td>{{ club.club }}</td>
							<td>
								<svg
									v-if="club.cancelacion_notas && !showRazon[club.id]"
									@click="showRazon[club.id] = true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="size-4 cursor-pointer"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="m4.5 15.75 7.5-7.5 7.5 7.5"
									/>
								</svg>

								<svg
									v-if="club.cancelacion_notas && showRazon[club.id]"
									@click="showRazon[club.id] = false"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="size-4 cursor-pointer"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="m19.5 8.25-7.5 7.5-7.5-7.5"
									/>
								</svg>
							</td>
						</tr>

						<tr v-if="showRazon[club.id]">
							<td
								colspan="7"
								class=""
							>
								<div class="flex flex-row space-x-4 w-full">
									{{ club.cancelacion_notas }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
	name: 'ClientesList',
	setup() {
		const clubs = ref([])
		const showRazon = ref([])

		const router = useRouter()

		function isProductClub(productId) {
			const clubsIds = [54727, 54728, 54729, 54730, 54731, 54732, 55549]
			return clubsIds.includes(productId)
		}

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		async function getClubs() {
			const { data, error } = await supabase
				.from('suscripciones')
				.select(
					'*, cliente(id, nombre, apellido, telefono, country_code, correo, reservas(id, estatus), pedidos(total, pedido_detalles(producto)))'
				)
				.eq('estatus', 'cancelado')
				.eq('cliente.reservas.estatus', 'Confirmado')
				.eq('cliente.pedidos.cancelado', false)
				.order('cancelacion_fecha', { ascending: false })

			if (error) {
				console.error(error)
			} else {
				return data
			}
		}

		onMounted(async () => {
			getClubs().then((data) => {
				clubs.value = data
			})
		})

		return {
			showRazon,
			goToCliente,
			clubs,
			isProductClub
		}
	}
}
</script>
