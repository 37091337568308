<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Blast</p>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="space-y-4">
				<div class="w-full space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Nombre</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Nombre"
							type="text"
							v-model="broadcast"
						/>
					</div>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Template</p>
					<select
						v-model="template"
						class="select select-sm select-bordered w-full"
					>
						<option
							selected
							disabled
							:value="null"
						>
							Escoger un template
						</option>
						<option
							v-for="template in templates"
							v-bind:key="template.elementName"
							:value="template.elementName"
						>
							{{ template.elementName }}
						</option>
					</select>
				</div>

				<div
					v-for="(filterKey, index) in Object.keys(filters.keys)"
					:key="index"
					class="w-full"
				>
					<p class="text-sm pb-2">{{ filterKey.charAt(0).toUpperCase() + filterKey.slice(1) }}</p>

					{{ selectedFilter[filterKey] }}
					<select
						v-model="selectedFilter[filterKey]"
						class="select select-sm select-bordered w-full"
						@change="addElement(filterKey, selectedFilter[filterKey])"
					>
						<option
							selected
							disabled
							:value="null"
						>
							Escoger un tipo de {{ filterKey }}
						</option>
						<option
							v-for="item in filters.keys[filterKey]"
							:key="item.key"
							:value="item"
						>
							{{ item.value }}
						</option>
					</select>

					<div class="mt-2 w-full flex flex-row items-center space-x-1">
						<div
							v-for="item in filters[filterKey]"
							:key="item.key"
							class="border border-1 border-slate-700 rounded-lg flex flex-row justify-between space-x-2 px-2 h-8 items-center"
						>
							<div class="text-sm">{{ item.value }}</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="size-5 cursor-pointer"
								@click="removeElement(filterKey, item)"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M6 18 18 6M6 6l12 12"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>

			<button
				@click="blast()"
				class="mt-4 btn btn-primary btn-sm no-animation w-full"
			>
				Blast
			</button>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<p class="text-xl">Clientes ({{ filteredClubs.length }})</p>

			<div class="flex w-full pt-4">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/5">Nombre</th>
							<th class="w-1/5">Telefono</th>
							<th class="w-1/5">Club</th>
							<th class="w-1/5">Cargo</th>
							<th class="w-1/5">Zona</th>
							<th class="w-1/5">Blasts</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="c in filteredClubs"
							v-bind:key="c.id"
							class="cursor-pointer"
						>
							<td @click="goToCliente(c.cliente.id)">
								{{ c.cliente.nombre }} {{ c.cliente.apellido }}
							</td>
							<td>{{ c.cliente.country_code }} {{ c.cliente.telefono }}</td>
							<td>{{ c.club }}</td>
							<td>{{ c.cargo }}</td>
							<td>{{ c.zona }}</td>
							<td>{{ !c.cliente.excluir_blasts }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'

export default {
	name: 'AdminCatas',
	setup() {
		const router = useRouter()

		const selectedFilter = ref({
			cargos: null,
			clubs: null,
			zonas: null
		})

		const filters = ref({
			keys: {
				cargos: [
					{
						key: 'mensual',
						value: 'Mensual'
					},
					{
						key: 'anual',
						value: 'Anual'
					}
				],
				zonas: [
					{
						key: 'local',
						value: 'Local'
					},
					{
						key: 'pickup',
						value: 'Pickup'
					},
					{
						key: 'foraneo',
						value: 'Foráneo'
					}
				],
				clubs: [
					{
						key: 'uno_blanco',
						value: 'Uno Blanco'
					},
					{
						key: 'uno_tinto',
						value: 'Uno Tinto'
					},
					{
						key: 'explorer',
						value: 'Explorer'
					},
					{
						key: 'fearless',
						value: 'Fearless'
					},
					{
						key: 'bold',
						value: 'Bold'
					},
					{
						key: 'bright',
						value: 'Bright'
					},
					{
						key: 'wild',
						value: 'Wild'
					}
				]
			},
			cargos: [],
			zonas: [],
			clubs: []
		})

		const broadcast = ref(null)
		const template = ref(null)
		const templates = ref([])
		const clubs = ref([])

		const filteredClubs = ref([])

		const loading = useLoading({
			loader: 'spinner'
		})

		async function getTemplates() {
			let { data } = await axios.get('/api/templates')

			return data
		}

		function addElement(element, value) {
			if (!value) return
			if (!filters.value[element].find((item) => item.key === value.key)) {
				filters.value[element].push(value)
			}
			selectedFilter.value[element] = null
			filterClubs()
		}

		function removeElement(element, value) {
			filters.value[element] = filters.value[element].filter((item) => item.key !== value.key)
			filterClubs()
		}

		async function getClubs() {
			const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(id, nombre, apellido, telefono, country_code, excluir_blasts)')
				.eq('estatus', 'activo')

			console.log(data, error)
			if (error) {
				console.error(error)
			} else {
				return data
			}
		}

		async function blast() {
			if (!broadcast.value) {
				return alert('Favor de ingresar un nombre')
			}

			if (!template.value) {
				return alert('Favor de escoger un template')
			}

			if (filteredClubs.value.filter((c) => !c.cliente.excluir_blasts).length == 0) {
				return alert('Nadie va a recibir este blast')
			}

			if (!confirm('¿Estás seguro del blast?')) {
				return
			}

			let loader = loading.show()

			let clubsToBlast = filteredClubs.value
				.filter((c) => !c.cliente.excluir_blasts)
				.map((c) => {
					return {
						whatsappNumber: `${c.cliente.country_code.replace(/\D/g, '')}1${c.cliente.telefono}`,
						customParams: [
							{
								name: 'nombre',
								value: c.cliente.nombre
							},
							{
								name: 'apellido',
								value: c.cliente.apellido
							},
							{
								name: 'correo',
								value: c.cliente.correo
							}
						]
					}
				})

			console.log(clubsToBlast)

			let { status } = await axios.post('/api/blast', {
				template_name: template.value,
				broadcast_name: broadcast.value,
				receivers: clubsToBlast
			})

			loader.hide()

			console.log(status)

			if (status == 200) {
				useToast().success('Mensajes mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}

			await addMessages(template, broadcast)

			broadcast.value = null
			template.value = null
		}

		async function addMessages(template, broadcast) {
			let mensajes = filteredClubs.value.map((c) => {
				return {
					template: template.value,
					nombre: broadcast.value,
					cliente: c.cliente.id
				}
			})

			const { error } = await supabase.from('mensajes').upsert(mensajes)

			if (error) {
				console.log(error)
				return alert(error)
			}

			useToast().success('Clientes actualizada', { position: 'top-right' })
		}

		function filterClubs() {
			filteredClubs.value = clubs.value.filter((item) => {
				const conditions = []

				if (filters.value.zonas.length > 0) {
					conditions.push(filters.value.zonas.some((z) => z.key === item.zona))
				}

				if (filters.value.clubs.length > 0) {
					conditions.push(filters.value.clubs.some((c) => c.key === item.club))
				}

				if (filters.value.cargos.length > 0) {
					conditions.push(filters.value.cargos.some((c) => c.key === item.cargo))
				}

				return conditions.length > 0 && conditions.every(Boolean)
			})
		}

		function goToCliente(cliente_id) {
			if (event.getModifierState('Meta')) {
				window.open(`/cliente/${cliente_id}`, '_blank').focus()
			} else {
				router.push(`/cliente/${cliente_id}`)
			}
		}

		onMounted(async () => {
			getClubs().then((data) => {
				console.log(data)
				clubs.value = data
			})

			getTemplates().then((data) => {
				templates.value = data.messageTemplates.filter(
					(t) => t.status == 'APPROVED' && t.elementName.includes('blast')
				)
			})
		})

		return {
			filterClubs,
			filteredClubs,
			goToCliente,
			broadcast,
			template,
			templates,
			clubs,
			blast,
			filters,
			addElement,
			removeElement,
			selectedFilter
		}
	}
}
</script>
