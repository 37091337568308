<template>
	<div class="p-4">
		<div class="flex justify-between">
			<div class="">
				<p class="text-2xl">Prospectos</p>
			</div>
			<div
				class="btn btn-sm btn-square no-animation btn-outline"
				@click="toggle()"
			>
				<svg
					v-if="!showNewProspecto"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="size-6"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M12 4.5v15m7.5-7.5h-15"
					/>
				</svg>
				<svg
					v-if="showNewProspecto"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="size-6"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M6 18 18 6M6 6l12 12"
					/>
				</svg>
			</div>
		</div>

		<div
			v-if="showNewProspecto"
			class="rounded-lg p-4 bg-base-100 mt-4"
		>
			<p class="text-xl">Nuevo prospecto</p>
			<div class="pt-4 space-y-4">
				<div class="flex flex-row space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Nombre</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Nombre"
							type="text"
							v-model="prospecto.nombre"
						/>
					</div>

					<div class="w-full">
						<p class="text-sm pb-2">Telefono</p>
						<div class="flex flex-row space-x-2">
							<select
								v-model="prospecto.country_code"
								class="select select-sm select-bordered w-20"
							>
								<option value="52">MX</option>
								<option value="1">USA</option>
							</select>

							<input
								class="input input-bordered input-sm w-full"
								placeholder="Telefono"
								type="text"
								v-model="prospecto.tel"
							/>
						</div>
					</div>
				</div>

				<div class="flex flex-row space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Estado</p>
						<select
							v-model="prospecto.estado"
							className="select select-sm select-block select-bordered w-full"
						>
							<option
								disabled
								value=""
							>
								Escoge un estado
							</option>
							<option
								v-for="estado in estados"
								v-bind:key="estado"
								:value="estado"
							>
								{{ estado }}
							</option>
						</select>
					</div>

					<div class="w-full">
						<p class="text-sm pb-2">Club</p>
						<select
							v-model="prospecto.club"
							className="select select-sm select-block select-bordered w-full"
						>
							<option
								disabled
								value=""
							>
								Escoge un club
							</option>
							<option value="Explorer">Explorer</option>
							<option value="Fearless">Fearless</option>
							<option value="Bold">Bold</option>
							<option value="Bright">Bright</option>
							<option value="Wild">Wild</option>
						</select>
					</div>
				</div>

				<div class="flex flex-row space-x-4">
					<div class="w-full">
						<p class="text-sm pb-2">Notas</p>
						<input
							class="input input-bordered input-sm w-full"
							placeholder="Notas"
							type="text"
							v-model="prospecto.notas"
						/>
					</div>
				</div>

				<button
					@click="createProspecto()"
					class="btn btn-primary btn-sm no-animation w-full"
				>
					Crear
				</button>
			</div>
		</div>

		<div class="rounded-lg p-4 bg-base-100 mt-4">
			<div class="flex flex-row cursor-pointer w-full">
				<div
					role="tablist"
					class="tabs tabs-boxed w-full"
				>
					<a
						@click="filter = 'por_contactar'"
						role="tab"
						class="tab"
						:class="[filter == 'por_contactar' ? 'tab-active' : '']"
						>Por contactar</a
					>
					<a
						@click="filter = 'por_cerrar'"
						role="tab"
						class="tab"
						:class="[filter == 'por_cerrar' ? 'tab-active' : '']"
						>Por cerrar</a
					>
					<a
						@click="filter = 'todo'"
						role="tab"
						class="tab"
						:class="[filter == 'todo' ? 'tab-active' : '']"
						>Todo</a
					>
				</div>
			</div>

			<div class="flex w-full pt-4 overflow-x-scroll">
				<table class="table table-xs">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th v-if="filter == 'por_cerrar'"></th>
							<th class="w-1/12">Fecha</th>
							<th class="w-1/12">Nombre</th>
							<th class="w-2/12">Número tel.</th>
							<th class="w-1/12">Correo</th>
							<th class="w-1/12">Estado</th>
							<th class="w-1/12">Club</th>
							<th class="w-1/12">Estatus</th>
							<th class="w-2/12">Notas</th>
							<th class="w-1/12">Suscrito</th>
							<th class="w-1/12">Acción</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="prospecto in prospectosFiltrados"
							v-bind:key="prospecto.id"
						>
							<td v-if="filter == 'por_cerrar'">
								<input
									class="checkbox no-animation"
									type="checkbox"
									v-model="prospecto.blast"
								/>
							</td>
							<td>
								<p>{{ prospecto.created_at.split('T')[0] }}</p>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.nombre }}</p>
								<input
									v-if="edit[prospecto.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="prospecto.copy.nombre"
								/>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.tel }}</p>
								<input
									v-if="edit[prospecto.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="prospecto.copy.tel"
								/>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.correo }}</p>
								<input
									v-if="edit[prospecto.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="prospecto.copy.correo"
								/>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.estado }}</p>
								<!-- <input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.estado"/> -->
								<select
									v-if="edit[prospecto.id]"
									v-model="prospecto.copy.estado"
									className="select select-sm select-block select-bordered w-full"
								>
									<option
										v-for="estado in estados"
										v-bind:key="estado"
										:value="estado"
									>
										{{ estado }}
									</option>
								</select>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.club }}</p>
								<select
									v-if="edit[prospecto.id]"
									v-model="prospecto.copy.club"
									className="select select-sm select-block select-bordered w-full"
								>
									<option value="Tinto">Tinto</option>
									<option value="Blanco">Blanco</option>
									<option value="Explorer">Explorer</option>
									<option value="Fearless">Fearless</option>
									<option value="Bold">Bold</option>
									<option value="Bright">Bright</option>
								</select>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.copy.estatus }}</p>
								<select
									v-if="edit[prospecto.id]"
									v-model="prospecto.copy.estatus"
									className="select select-sm select-block select-bordered w-full"
								>
									<option value="Contacto">Contacto</option>
									<option value="Perdido">Perdido</option>
									<option value="Cerrado nosotros">Cerrado nosotros</option>
									<option value="Cerrado solito">Cerrado solito</option>
									<option value="Duplicado">Duplicado</option>
								</select>
							</td>
							<td>
								<p v-if="!edit[prospecto.id]">{{ prospecto.notas }}</p>
								<input
									v-if="edit[prospecto.id]"
									class="input input-bordered input-sm w-full"
									placeholder=""
									type="text"
									inputmode="text"
									v-model="prospecto.copy.notas"
								/>
							</td>
							<td>
								{{ hasClub(prospecto.correo) ? 'Si' : 'No' }}
							</td>
							<td>
								<div class="flex flex-row space-x-2">
									<button
										v-if="!edit[prospecto.id]"
										@click="edit[prospecto.id] = true"
										class="btn btn-primary btn-sm no-animation"
										style="width: 4.5rem"
									>
										Editar
									</button>
									<button
										v-if="edit[prospecto.id]"
										@click="updateProspecto(prospecto)"
										class="btn btn-sm btn-square no-animation btn-primary"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="m4.5 12.75 6 6 9-13.5"
											/>
										</svg>
									</button>
									<button
										v-if="edit[prospecto.id]"
										class="btn btn-sm btn-square no-animation btn-neutral"
										@click="cancelEdit(prospecto)"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6 w-8"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M6 18 18 6M6 6l12 12"
											/>
										</svg>
									</button>
									<button
										@click="mandarMensaje(prospecto)"
										class="btn btn-sm btn-square no-animation btn-primary"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											class="size-6"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
											/>
										</svg>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div
			v-if="filter == 'por_cerrar'"
			class="w-full pt-4"
		>
			<p class="text-sm pb-2">Template</p>
			<select
				v-model="template"
				class="select select-sm select-bordered w-full"
			>
				<option
					selected
					disabled
					:value="null"
				>
					Escoger un template
				</option>
				<option
					v-for="template in templates"
					v-bind:key="template.elementName"
					:value="template.elementName"
				>
					{{ template.elementName }}
				</option>
			</select>
		</div>

		<button
			v-if="filter == 'por_cerrar'"
			@click="blast()"
			class="mt-4 btn btn-primary btn-sm no-animation w-full"
		>
			Mandar oferta
		</button>
	</div>
</template>
<script>
import { supabase } from '../supabase'
import { onMounted, ref, computed } from 'vue'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'
import axios from 'axios'

export default {
	name: 'AdminCatas',
	setup() {
		let prospectos = ref([])
		let clubs = ref([])

		let edit = ref({})

		let filter = ref('por_contactar')

		const loading = useLoading({
			loader: 'spinner'
		})

		const template = ref(null)
		const templates = ref([])

		const prospecto = ref({ country_code: 52, estado: '', club: '' })

		const showNewProspecto = ref(false)
		const estados = ref([
			'Aguascalientes',
			'Baja California',
			'Baja California Sur',
			'Campeche',
			'Chiapas',
			'Chihuahua',
			'Ciudad de México',
			'Coahuila',
			'Colima',
			'Durango',
			'Estado de México',
			'Guanajuato',
			'Guerrero',
			'Hidalgo',
			'Jalisco',
			'Michoacán',
			'Morelos',
			'Nayarit',
			'Nuevo León',
			'Oaxaca',
			'Puebla',
			'Querétaro',
			'Quintana Roo',
			'San Luis Potosí',
			'Sinaloa',
			'Sonora',
			'Tabasco',
			'Tamaulipas',
			'Tlaxcala',
			'Veracruz',
			'Yucatán',
			'Zacatecas'
		])

		async function getProspectos() {
			const { data, error } = await supabase
				.from('prospectos')
				.select('*')
				.order('created_at', { ascending: false })

			console.log(data, error)

			if (data) {
				return data.map((d) => {
					d.copy = structuredClone(d)

					return d
				})
			} else if (error) {
				console.log(error)
			} else {
				alert('No data returned')
			}
		}

		async function getClubs() {
			const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(correo)')
				.eq('estatus', 'activo')

			if (error) {
				console.error(error)
			} else {
				return data.map((c) => c.cliente.correo)
			}
		}

		function hasClub(correo) {
			return clubs.value.includes(correo)
		}

		async function updateProspecto(prospecto) {
			if (!confirm('¿Quieres guardar tus cambios?')) {
				return cancelEdit(prospecto)
			}

			const { error } = await supabase.from('prospectos').upsert(prospecto.copy)

			if (error) {
				return alert(error.details)
			}

			prospectos.value = replaceObjectById(prospectos.value, prospecto.copy)

			console.log(prospectos.value)

			useToast().success('Prospecto actualizada', { position: 'top-right' })

			edit.value[prospecto.id] = false
		}

		function formatDateEuroStyle(fecha) {
			let components = fecha.split('-')

			return `${components[2]}/${components[1]}/${components[0]}`
		}

		const prospectosFiltrados = computed(() => {
			return prospectos.value.filter((p) => {
				switch (filter.value) {
					case 'por_contactar':
						return !p.estatus
					case 'por_cerrar':
						return (
							p.estatus != 'Cerrado solito' &&
							p.estatus != 'Cerrado nosotros' &&
							p.estatus != 'Perdido' &&
							p.estatus != 'Duplicado'
						)
					default:
						return true
				}
			})
		})

		const replaceObjectById = (array, updatedObject) => {
			updatedObject.copy = structuredClone(updatedObject)
			return array.map((item) => (item.id === updatedObject.id ? updatedObject : item))
		}

		function cancelEdit(prospecto) {
			edit.value[prospecto.id] = false

			delete prospecto.copy
			prospecto.copy = structuredClone(prospecto)

			prospectos.value = replaceObjectById(prospectos.value, prospecto.copy)

			// prospectos.value = structuredClone(prospectosCopy.value)
		}

		onMounted(async () => {
			getProspectos().then((data) => {
				prospectos.value = data
				// prospectosCopy.value = JSON.parse(JSON.stringify(data))
			})

			getClubs().then((data) => {
				clubs.value = data
			})

			getTemplates().then((data) => {
				templates.value = data.messageTemplates.filter(
					(t) => t.status == 'APPROVED' && t.elementName.includes('blast')
				)
			})
		})

		async function mandarMensaje(prospecto) {
			if (!confirm('¿Quieres mandar el mensaje?')) {
				return
			}

			if (!prospecto.estado) {
				return alert('No tiene estado')
			}

			let loader = loading.show()

			let template = ''

			if (prospecto.estado == 'Ciudad de México' || prospecto.estado == 'Estado de México') {
				template = 'prospecto_local_v2'
			} else {
				template = 'prospecto_foraneo'
			}

			let { status } = await axios.post('/api/blast', {
				template_name: template,
				broadcast_name: `prospecto_${prospecto.tel}`,
				receivers: [
					{
						whatsappNumber: `${prospecto.tel.replaceAll(' ', '')}`,
						customParams: [
							{
								name: 'nombre',
								value: prospecto.nombre.split(' ')[0]
							},
							{
								name: 'club',
								value: prospecto.club
							}
						]
					}
				]
			})

			loader.hide()

			if (status == 200) {
				useToast().success('Mensaje mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}
		}

		async function getTemplates() {
			let { data } = await axios.get('/api/templates')

			return data
		}

		function toggle() {
			showNewProspecto.value = !showNewProspecto.value
		}

		async function createProspecto() {
			if (!prospecto.value.nombre || !prospecto.value.tel || !prospecto.value) {
				return alert('Falta info')
			}

			console.log(prospecto.value)

			const { data, error } = await supabase
				.from('prospectos')
				.upsert({
					nombre: prospecto.value.nombre,
					tel: `${prospecto.value.country_code} ${prospecto.value.tel}`,
					estado: prospecto.value.estado,
					club: prospecto.value.club,
					notas: prospecto.value.notas
				})
				.select('*')
				.single()

			if (error) {
				console.log(error)
				alert('Hubo un error:', error)
			}

			prospecto.value = { country_code: 52, estado: '', club: '' }
			prospectos.value.unshift(data)
		}

		async function blast() {
			if (!confirm('¿Estás seguro del blast?')) {
				return
			}

			if (!template.value) {
				return alert('Escoge un template')
			}

			let loader = loading.show()

			let prospectosToBlast = prospectosFiltrados.value
				.filter((p) => p.blast)
				.map((p) => {
					return {
						whatsappNumber: `${p.tel.replaceAll(' ', '')}`,
						customParams: [
							{
								name: 'nombre',
								value: p.nombre.split(' ')[0]
							}
						]
					}
				})

			console.log(prospectosToBlast)

			let { status } = await axios.post('/api/blast', {
				template_name: template.value,
				broadcast_name: 'blast_prospectos',
				receivers: prospectosToBlast
			})

			loader.hide()

			if (status == 200) {
				useToast().success('Mensajes mandado', { position: 'top-right' })
			} else {
				useToast().error('Hubo un error, pregúntale a Noah', { position: 'top-right' })
			}

			// console.log(status)
		}

		return {
			edit,
			cancelEdit,
			updateProspecto,
			prospectosFiltrados,
			formatDateEuroStyle,
			filter,
			mandarMensaje,
			hasClub,
			estados,
			blast,
			template,
			templates,
			showNewProspecto,
			toggle,
			prospecto,
			createProspecto
		}
	}
}
</script>
