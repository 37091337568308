<template>
	<div class="p-4">
		<p class="text-2xl">Transfer</p>

		<div class="rounded-lg p-4 bg-base-100 mt-4 space-y-4">
			<div class="grid grid-cols-2">
				<p class="text-lg">Desde: {{ transfer ? transfer.from.nombre : '' }}</p>
				<p class="text-lg">Hacia: {{ transfer ? transfer.to.nombre : '' }}</p>
			</div>

			<div class="flex w-full">
				<table class="table table-sm">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th>Producto</th>
							<th>Cantidad</th>
							<th>Recibido</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="salida in salidas"
							v-bind:key="salida.id"
						>
							<th>{{ salida.producto.nombre }}</th>
							<th>{{ salida.cantidad }}</th>
							<th></th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { supabase } from '../supabase'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
	name: 'AdminTransfers',
	setup() {
		const transfer = ref(null)
		const salidas = ref([])
		const route = useRoute()

		async function getSalidas() {
			const { data } = await supabase
				.from('salidas')
				.select('id, producto (nombre), cantidad')
				.eq('transfer', route.params.transfer_id)

			console.log(data)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		async function getTransfer() {
			const { data } = await supabase
				.from('transfers')
				.select('id, created_at, from (nombre), to (nombre)')
				.order('created_at', { ascending: false })
				.eq('id', route.params.transfer_id)
				.single()

			console.log(data)

			if (data) {
				return data
			} else {
				alert('No data returned')
			}
		}

		onMounted(async () => {
			transfer.value = await getTransfer()
			salidas.value = await getSalidas()
		})

		return {
			transfer,
			salidas
		}
	}
}
</script>
